exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archive-js": () => import("./../../../src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-library-js": () => import("./../../../src/pages/library.js" /* webpackChunkName: "component---src-pages-library-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-prismic-exhibition-url-js": () => import("./../../../src/pages/{PrismicExhibition.url}.js" /* webpackChunkName: "component---src-pages-prismic-exhibition-url-js" */),
  "component---src-pages-prismic-residency-url-js": () => import("./../../../src/pages/{PrismicResidency.url}.js" /* webpackChunkName: "component---src-pages-prismic-residency-url-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-residency-js": () => import("./../../../src/pages/residency.js" /* webpackChunkName: "component---src-pages-residency-js" */)
}

