import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";
import { use100vh } from "react-div-100vh";
import { Helmet } from "react-helmet";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Utils
import { FormatDate } from "../components/utils/format-date";

// SEO
import { PageSeo } from "../components/seo/page-seo";
import { GatsbyImage } from "gatsby-plugin-image";

const Page = styled.div`
  padding: 250px 30px 50px 30px;

  & .thumbnail-container {
    grid-column: 1 / 6;

    height: calc(${(props) => props.height}px - 250px);

    position: sticky;
    top: 100px;

    & img {
      position: absolute;
      top: 0;
    }

    &.portrait {
      & img {
        max-width: 60%;
        height: auto;
        margin: 0 auto;
      }
    }

    @media (hover: none) and (pointer: coarse) {
      display: none;
    }
  }

  & .content-container {
    grid-column: 6 / 13;

    & ol {
      & li {
        margin: 0 0 1em 0;

        width: fit-content;
        transition: 250ms color ease;

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            color: #999999;
          }
        }
      }
    }
  }

  & .title-container {
    & p {
      margin: 0;
    }
  }

  @media (max-width: 860px) {
    padding: 120px 20px 20px 20px;

    & .content-container {
      grid-column: unset;
      padding: 0 0 0 40px;
    }
  }
`;

const Archive = ({ data }) => {
  const [activeProject, setActiveProject] = useState(null);

  // Window Height
  const height = use100vh();

  const content = data.allPrismicExhibition.nodes
    .filter((item) => {
      if (data.prismicHome.data.current_exhibition.document !== null) {
        return item.id !== data.prismicHome.data.current_exhibition.document.id;
      } else {
        return item;
      }
    })
    .map((item, index) => (
      <li
        key={`single_archived_exhibition_${index}_${item.id}`}
        onMouseEnter={() => setActiveProject(item.data)}
        onMouseLeave={() => setActiveProject(null)}
      >
        <Link to={item.url}>
          <div className="title-container">
            <h1>
              <PrismicRichText
                field={item.data.artist.richText}
                components={{
                  heading2: ({ children }) => (
                    <span className="">{children}, </span>
                  ),
                }}
              />

              <PrismicRichText
                field={item.data.title.richText}
                components={{
                  heading1: ({ children }) => (
                    <span className="italic">{children}</span>
                  ),
                }}
              />
            </h1>

            <PrismicRichText field={item.data.additional_info.richText} />

            <p>{FormatDate(item.data.start_date, item.data.end_date)}</p>
          </div>
        </Link>
      </li>
    ));

  const preloadedImages = data.allPrismicExhibition.nodes
    .filter((node) => node.data.desktop_thumbnail.gatsbyImageData !== null)
    .map((node, index) => (
      <link
        rel="preload"
        imagesrcset={
          node.data.desktop_thumbnail.gatsbyImageData.images.fallback.srcSet
        }
        as="image"
        key={`preloaded_image_${index}`}
      />
    ));

  return (
    <>
      <PageSeo seoTitle={`Archive`} seoText={null} seoImage={null} />

      <Helmet>{preloadedImages}</Helmet>

      <Page height={height}>
        <div className="grid">
          {activeProject !== null && (
            <>
              {activeProject.desktop_thumbnail !== null && (
                <>
                  {activeProject.desktop_thumbnail.dimensions !== null && (
                    <div
                      className={`thumbnail-container ${
                        activeProject.desktop_thumbnail.dimensions.width >
                        activeProject.desktop_thumbnail.dimensions.height
                          ? `landscape`
                          : `portrait`
                      }`}
                    >
                      <>
                        {activeProject.desktop_thumbnail.gatsbyImageData !==
                          null && (
                          <GatsbyImage
                            image={
                              activeProject.desktop_thumbnail.gatsbyImageData
                            }
                            alt={`${activeProject.artist.text}, ${activeProject.title.text}`}
                          />
                        )}
                      </>
                    </div>
                  )}
                </>
              )}
            </>
          )}

          <div className="content-container">
            <ol>{content}</ol>
          </div>
        </div>
      </Page>
    </>
  );
};

export default withPrismicPreview(Archive);

export const query = graphql`
  query {
    prismicHome {
      data {
        current_exhibition {
          document {
            ... on PrismicExhibition {
              id
            }
          }
        }
      }
    }
    allPrismicExhibition(sort: { fields: data___start_date, order: DESC }) {
      nodes {
        id
        url
        data {
          title {
            richText
            text
          }
          artist {
            richText
            text
          }
          additional_info {
            richText
          }
          start_date
          end_date
          desktop_thumbnail {
            gatsbyImageData(layout: FULL_WIDTH, width: 500)
            alt
            dimensions {
              width
              height
            }
          }
        }
      }
    }
  }
`;
