import * as React from "react";
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews";

// Prismic
import { linkResolver } from "./src/components/link-resolver/linkResolver";

// Pages
import Exhibition from "./src/pages/{PrismicExhibition.url}";
import Residency from "./src/pages/{PrismicResidency.url}";

import Archive from "./src/pages/archive";
import Index from "./src/pages/index";
import Info from "./src/pages/info";
import Library from "./src/pages/library";
import News from "./src/pages/news";
import PrivacyPolicy from "./src/pages/privacy-policy";
import Residencies from "./src/pages/residency";

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
        linkResolver,
        componentResolver: componentResolverFromMap({
          exhibition: Exhibition,
          residency: Residency,
          archive: Archive,
          index: Index,
          info: Info,
          library: Library,
          news: News,
          privacy_policy: PrivacyPolicy,
          residencies: Residencies,
        }),
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
);
