import React from "react";
import styled from "styled-components";

const FloatingNavigationContainer = styled.div`
  /* display: none; */

  position: sticky;
  top: 0;

  & .inner-navigation-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    max-width: 970px;
    width: 100%;

    margin: 0 auto;
    padding: 20px 30px;
  }

  &.exhibition {
    background: #eee;
    color: #000;
  }

  &.residency {
    background: #000;
    color: #eee;
  }

  & button {
    width: 110px;
    /* text-decoration: underline; */
    /* text-decoration-color: transparent; */
    /* text-decoration-thickness: from-font; */
    /* text-underline-offset: 3px; */

    /* transition: 250ms text-decoration-color ease; */

    text-align: center;

    &:first-of-type {
      text-align: left;
    }

    &:last-of-type {
      text-align: right;
    }

    &:hover,
    &.active {
      font-style: italic;
      /* text-decoration-color: #000; */
    }
  }

  @media (max-width: 860px) {
    & .inner-navigation-container {
      padding: 20px;
    }
  }

  @media (max-width: 380px) {
    & button {
      width: fit-content;
    }
  }
`;

export const FloatingNavigation = ({
  gallery,
  pressRelease,
  references,
  pageType,
  setIsLightboxOpen,
}) => {
  //   galleryRefIntersection,
  // pressReleaseRefIntersection,
  // referencesRefIntersection,
  // console.log(
  //   galleryRefIntersection && galleryRefIntersection.intersectionRatio >= 0.5
  // );
  // console.log(
  //   pressReleaseRefIntersection &&
  //     pressReleaseRefIntersection.intersectionRatio >= 0.5
  // );
  // console.log(
  //   referencesRefIntersection &&
  //     referencesRefIntersection.intersectionRatio >= 0.5
  // );

  return (
    <FloatingNavigationContainer className={pageType}>
      <div className="inner-navigation-container">
        {gallery && (
          <button
            // className={
            //   galleryRefIntersection &&
            //   galleryRefIntersection.intersectionRatio >= 0.5
            //     ? "active"
            //     : ""
            // }
            onClick={() => setIsLightboxOpen(true)}
          >
            Artworks
          </button>
        )}

        {pressRelease && (
          <button
            // className={
            //   pressReleaseRefIntersection &&
            //   pressReleaseRefIntersection.intersectionRatio >= 0.5
            //     ? "active"
            //     : ""
            // }
            onClick={() => {
              if (typeof window !== `undefined`) {
                document.getElementById(`content-container`).scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }
            }}
          >
            Text
          </button>
        )}

        {references && (
          <button
            // className={
            //   referencesRefIntersection &&
            //   referencesRefIntersection.intersectionRatio >= 0.5
            //     ? "active"
            //     : ""
            // }
            onClick={() => {
              if (typeof window !== `undefined`) {
                document.getElementById(`references-container`).scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }
            }}
          >
            References
          </button>
        )}
      </div>
    </FloatingNavigationContainer>
  );
};
