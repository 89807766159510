import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

const LoadingSpinner = styled.div`
  display: ${(props) => props.display};
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 10;

  & p {
    width: 100%;
    margin: 60px 0 0 0;

    font-size: calc(14px + (18 - 14) * ((100vw - 767px) / (1400 - 767)));
    line-height: 150%;
    text-align: center;

    color: ${(props) => props.color};

    @media (min-width: 1400px) {
      font-size: 18px;
    }

    @media (max-width: 767px) {
      font-size: 14px;
    }
  }

  & > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
  }
`;

export const GalleryImage = ({ image, alt, pageType, index }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <>
      <LoadingSpinner
        display={imageLoaded === true ? `none` : `flex`}
        color={pageType === `exhibition` ? `#999` : `#fff`}
      >
        <div>
          <p>Please wait, image loading</p>
        </div>
      </LoadingSpinner>

      {image !== null && (
        <GatsbyImage
          image={image}
          alt={alt}
          onLoad={() => setImageLoaded(true)}
          fetchpriority={index <= 1 ? `high` : ``}
          loading={index <= 1 ? `eager` : `lazy`}
        />
      )}
    </>
  );
};
