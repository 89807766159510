import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql, Link } from "gatsby";
import { useWindowScroll } from "react-use";
import kebabCase from "lodash.kebabcase";

// Hooks
import { useMediaQuery } from "../hooks/useMediaQuery";

// Components
import { DesktopMenu } from "../navigation/desktop-menu";
import { MobileMenu } from "../navigation/mobile-menu";

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  z-index: 3000;

  transform: translateY(${(props) => props.translateY});
`;

export const Header = ({ location }) => {
  // Media Query
  let isDesktop = useMediaQuery("(min-width: 861px)");

  // Slippy Menu
  const { x, y } = useWindowScroll();

  // Header scroll
  const [lastScroll, setLastScroll] = useState(0);
  const [isMenuVisible, setIsMenuVisible] = useState(true);

  useEffect(() => {
    if (y <= 0) {
      setIsMenuVisible(true);
      return;
    }

    if (isDesktop) {
      if (y > lastScroll && y > 140 && isMenuVisible) {
        // down
        setIsMenuVisible(false);
      } else if (y < lastScroll && !isMenuVisible) {
        // up
        setIsMenuVisible(true);
      }
    } else {
      if (y > lastScroll && y > 70 && isMenuVisible) {
        // down
        setIsMenuVisible(false);
      } else if (y < lastScroll && !isMenuVisible) {
        // up
        setIsMenuVisible(true);
      }
    }
    setLastScroll(y);
  }, [y, lastScroll, setLastScroll, setIsMenuVisible, isDesktop]);

  const data = useStaticQuery(graphql`
    query {
      prismicHome {
        data {
          current_exhibition {
            document {
              ... on PrismicExhibition {
                id
                url
              }
            }
          }
        }
      }
      prismicMenu {
        data {
          menu {
            page {
              document {
                ... on PrismicHome {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicNews {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicLibrary {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicResidencies {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicArchive {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicInfo {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const links = data.prismicMenu.data.menu
    .filter((page) => page.page.document !== null)
    .map((page, index) => {
      if (page.page.document.url === `/`) {
        return (
          <li
            key={`single_link_${page.page.document.id}_${index}`}
            id={`link-${kebabCase(page.page.document.data.title.text)}`}
          >
            <Link
              to={page.page.document.url}
              activeClassName="current"
              className={location.includes(`/exhibition/`) ? `current` : ``}
            >
              {page.page.document.data.title.text}
            </Link>
          </li>
        );
      } else {
        return (
          <li
            key={`single_link_${page.page.document.id}_${index}`}
            id={`link-${kebabCase(page.page.document.data.title.text)}`}
          >
            <Link
              to={page.page.document.url}
              activeClassName="current"
              partiallyActive
            >
              {page.page.document.data.title.text}
            </Link>
          </li>
        );
      }
    });

  const currentExhibition =
    data.prismicHome.data.current_exhibition.document !== null
      ? data.prismicHome.data.current_exhibition.document.url
      : null;

  return (
    <HeaderWrapper translateY={isMenuVisible ? `0` : `-100%`}>
      {isDesktop ? (
        <DesktopMenu
          links={links}
          location={location}
          currentExhibition={currentExhibition}
        />
      ) : (
        <MobileMenu
          links={links}
          location={location}
          currentExhibition={currentExhibition}
        />
      )}
    </HeaderWrapper>
  );
};
