import React from "react";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";
import Modal from "react-modal";
import { RemoveScroll } from "react-remove-scroll";

const SplashContainer = styled.div`
  opacity: ${(props) => props.opacity};
  transition: opacity 500ms ease;

  background-color: #eeeeee;
`;

const OneLiner = styled.div`
  height: ${(props) => props.height}px;

  & .text-container {
    display: flex;
    align-items: center;
    justify-content: center;

    height: ${(props) => props.height}px;

    & h1 {
      font-size: 44px;
      transition: 500ms transform ease, 600ms opacity ease;
      color: #000;
    }
  }

  &.transition-start {
    & .text-container {
      & h1 {
        transform: scale(1.03);
        opacity: 0;
      }
    }
  }

  &.transition-end {
    & .text-container {
      & h1 {
        transform: scale(1);
        opacity: 1;
      }
    }
  }

  @media (max-width: 860px) {
    & .text-container {
      & h1 {
        font-size: 25px;
      }
    }
  }
`;

Modal.setAppElement("#___gatsby");

export const Splash = ({
  setIsSplashOpen,
  isSplashOpen,
  transitionStatus,
  splashOpacity,
}) => {
  // Window Height
  const height = use100vh();

  return (
    <RemoveScroll>
      <Modal
        isOpen={isSplashOpen}
        portalClassName={`ReactModalPortal-splash`}
        className="splash-modal"
        contentLabel={`Splash`}
        shouldFocusAfterRender={false}
      >
        <SplashContainer opacity={splashOpacity}>
          <OneLiner
            height={height}
            className={`transition-${transitionStatus}`}
          >
            <div
              className="text-container"
              // onClick={() => setIsSplashOpen(false)}
            >
              <h1>Painters Painting Paintings.</h1>
            </div>
          </OneLiner>
        </SplashContainer>
      </Modal>
    </RemoveScroll>
  );
};
