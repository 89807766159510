import React, { useEffect, useState } from "react";
import { Location } from "@reach/router";
import styled from "styled-components";
import { Normalize } from "styled-normalize";
import { use100vh } from "react-div-100vh";
import kebabCase from "lodash.kebabcase";
import smoothscroll from "smoothscroll-polyfill";

// Get Urls correct
import { Link } from "gatsby";
import { PrismicProvider } from "@prismicio/react";
import { linkResolver } from "../link-resolver/linkResolver";

// Components
import { Splash } from "./splash";

// Styles
import GlobalStyles from "../styles/globalStyles";

// Components
import { App } from "./app";
import { DefaultSEO } from "./default-seo";

// Transitions
import { Transition } from "../containers/transition";

const Container = styled.div`
  position: relative;

  min-height: ${(props) => props.height}px;

  opacity: ${(props) => props.appOpacity};
  transition: 300ms opacity ease;
`;

const SplashWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  cursor: pointer;

  z-index: 1000;

  transform: translateY(${(props) => (props.disable ? "-100%" : "0")});
`;

const Layout = ({ children }) => {
  const height = use100vh();

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  useEffect(() => {
    console.log(
      "%cDevelopment: Kieran Startup: www.kieranstartup.co.uk",
      "color:red; font-family:Helvetica Neue;  font-size: 20px"
    );
  }, []);

  const [splashOpacity, setSplashOpacity] = useState(1);
  const [isSplashOpen, setIsSplashOpen] = useState(true);
  const [transitionStatus, setTransitionStatus] = useState("start");
  const [appOpacity, setAppOpacity] = useState(0);

  useEffect(() => {
    const triggerTransition = setTimeout(() => {
      setTransitionStatus(`end`);

      const splashOpacityTimer = setTimeout(() => {
        setSplashOpacity(0);

        const splashOpenTimer = setTimeout(() => {
          setIsSplashOpen(false);

          const fadeInContent = setTimeout(() => {
            setAppOpacity(1);
          }, 100);
          return () => clearTimeout(fadeInContent);
        }, 500);
        return () => clearTimeout(splashOpenTimer);
      }, 1500);
      return () => clearTimeout(splashOpacityTimer);
    }, 500);
    return () => clearTimeout(triggerTransition);
  }, []);

  return (
    <PrismicProvider
      linkResolver={linkResolver}
      internalLinkComponent={({ href, ...props }) => (
        <Link to={href} {...props} />
      )}
    >
      <Location>
        {({ location }) => {
          return (
            <Container
              className={kebabCase(location.pathname)}
              height={height}
              appOpacity={appOpacity}
            >
              <Normalize />
              <GlobalStyles />
              <DefaultSEO />

              {isSplashOpen && (
                <Splash
                  setIsSplashOpen={setIsSplashOpen}
                  isSplashOpen={isSplashOpen}
                  transitionStatus={transitionStatus}
                  splashOpacity={splashOpacity}
                />
              )}

              <Transition children={children} location={location}>
                <App location={location.pathname} children={children} />
              </Transition>
            </Container>
          );
        }}
      </Location>
    </PrismicProvider>
  );
};

export default Layout;
