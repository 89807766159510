import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const Navigation = styled.nav`
  padding: 10px 20px;

  & button {
    display: block;
    margin: 0 auto;

    appearance: none;
    overflow: visible;

    line-height: 40px;
  }

  & ol {
    width: 100%;
    justify-content: center;

    & li {
      &::after {
        content: ", ";
        padding: 0 10px 0 0;
      }

      &:last-of-type {
        &::after {
          content: "";
        }
      }

      & a {
        &.current {
          font-style: italic;
        }
      }
    }
  }
`;

export const MobileMenu = ({ links, location, currentExhibition }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <Navigation
      className={`display-flex space-between menu-${
        isMenuOpen ? `open` : `closed`
      }`}
    >
      <button type="button" onClick={() => setIsMenuOpen(!isMenuOpen)}>
        Painters Painting Paintings.
      </button>

      {isMenuOpen && (
        <ol className="display-flex">
          {currentExhibition !== null && (
            <li className="exhibition">
              <Link to={currentExhibition} activeClassName="current">
                Exhibition
              </Link>
            </li>
          )}

          {links}
        </ol>
      )}
    </Navigation>
  );
};
