import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const Navigation = styled.nav`
  padding: 0 30px;
  line-height: 90px;

  & ol {
    & li {
      display: block;

      &#link-exhibition {
        width: 108px;
      }

      &#link-archive {
        width: 83px;
      }

      &#link-residency {
        width: 104px;
      }

      &#link-info {
        width: 48px;
      }

      &#link-library {
        width: 78px;
      }

      &#link-news {
        width: 63px;
      }

      &::after {
        content: ", ";
      }

      &:last-of-type {
        &::after {
          content: "";
        }
      }

      text-align: center;

      & a {
        display: inline-block;

        &.current,
        &:hover {
          font-style: italic;
        }
      }
    }
  }
`;

export const DesktopMenu = ({ links, location, currentExhibition }) => (
  <Navigation className="display-flex space-between">
    <Link to={`/`}>Painters Painting Paintings.</Link>

    <ol className="display-flex">
      {currentExhibition !== null && (
        <li className="exhibition" id="link-exhibition">
          <Link to={currentExhibition} activeClassName="current">
            Exhibition
          </Link>
        </li>
      )}

      {links}
    </ol>
  </Navigation>
);
