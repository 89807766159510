import React, { useEffect } from "react";
import { useSessionStorage } from "react-use";

// Utils
import kebabCase from "lodash.kebabcase";

// Components
import { Header } from "./header";
import { MailingList } from "../forms/mailchimp";
import { Cookies } from "../cookies/cookies";

export const App = ({ location, children }) => {
  const [isMailchimpVisible, setIsMailchimpVisible] = useSessionStorage(
    "isMailchimpVisible",
    true
  );

  useEffect(() => {
    document.body.classList.remove(...document.body.classList);

    if (location !== "/") {
      document.body.classList.add(kebabCase(location));
    }
  }, [location]);

  return (
    <>
      <Header location={location} />
      <main>{children}</main>

      {isMailchimpVisible && (
        <MailingList
          location={location}
          setIsMailchimpVisible={setIsMailchimpVisible}
        />
      )}

      <Cookies location={location} />
    </>
  );
};
