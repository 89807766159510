import React from "react";
import styled from "styled-components";

const Icon = styled.svg``;

export const Open = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_485_226)">
      <path d="M0 6H12" stroke="black" stroke-width="1.5" />
      <path d="M6 0V12" stroke="black" stroke-width="1.5" />
    </g>
    <defs>
      <clipPath id="clip0_485_226">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const Close = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_485_206)">
      <path d="M0 6H12" stroke="black" stroke-width="1.5" />
    </g>
    <defs>
      <clipPath id="clip0_485_206">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
