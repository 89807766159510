import React, { useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
// import { GatsbyImage } from "gatsby-plugin-image";
import { PrismicRichText } from "@prismicio/react";
import { RemoveScroll } from "react-remove-scroll";
import { useMeasure } from "react-use";

// Fullpage
import ReactFullpage from "@fullpage/react-fullpage";

// Components
import { GalleryImage } from "./image";

// Icons
import { CloseIcon } from "../icons/close-icons";
import { ArtworkEnquiryForm } from "../forms/artwork-enquiry";

const FullPageContainer = styled.div`
  color: #000;

  & .fp-watermark {
    display: none;
  }

  & .fp-table {
    justify-content: flex-start;
  }

  & .fp-arrow {
    position: absolute;
    top: 0;
    bottom: 0;

    height: 100%;
    width: 50%;

    z-index: 10;

    margin: 0;
    transform: unset;
    user-select: unset;

    &.fp-prev,
    &.fp-next {
      border-width: unset;
      border-color: unset;
      border: 0;
    }

    &.fp-prev {
      left: 0;
      cursor: w-resize;

      cursor: url(/icons/exhibition-arrow-previous@1x.png) 9 9, w-resize !important;
      cursor: -webkit-image-set(
            url(/icons/exhibition-arrow-previous@1x.png) 1x,
            url(/icons/exhibition-arrow-previous@2x.png) 2x
          )
          9 9,
        w-resize !important;
    }

    &.fp-next {
      right: 0;
      cursor: e-resize;

      cursor: url(/icons/exhibition-arrow-next@1x.png) 9 9, e-resize !important;
      cursor: -webkit-image-set(
            url(/icons/exhibition-arrow-next@1x.png) 1x,
            url(/icons/exhibition-arrow-next@2x.png) 2x
          )
          9 9,
        e-resize !important;
    }
  }

  & .fp-slidesNav {
    display: none;

    bottom: calc(${(props) => props.minHeight}px + 20px + 20px) !important;
    height: 2px;

    & ul {
      display: grid;

      height: 2px;

      & li {
        width: 100%;

        height: 2px;
        margin: 0;

        & a {
          & span {
            width: 100%;
            height: 2px;
            display: block;

            margin: 0;
            border-radius: 0;
            position: relative;
            left: unset;
            top: unset;

            margin: 0;
            border-radius: 0;

            background-color: #eee;

            &.fp-sr-only {
              display: none;
            }
          }

          &.active {
            & span {
              width: 100%;
              height: 2px;
              display: block;

              margin: 0;
              border-radius: 0;
              position: relative;
              left: unset;
              top: unset;

              margin: 0;
              border-radius: 0;

              background-color: #000;
            }
          }
        }
      }
    }
  }

  @media (max-width: 860px) {
    & .fp-slidesNav {
      bottom: calc(${(props) => props.minHeight}px + 20px + 20px) !important;
    }
  }
`;

const SingleSlide = styled.div`
  position: relative;
  height: calc(${(props) => props.height}px - 102px) !important;
  overflow: hidden;

  & .gatsby-image-wrapper {
    height: calc(${(props) => props.height}px - 102px) !important;

    & img {
      height: calc(${(props) => props.height}px - 102px) !important;
      object-fit: contain !important;
    }
  }

  @media (max-width: 860px) {
    height: calc(${(props) => props.height}px - 82px) !important;

    & .gatsby-image-wrapper {
      height: calc(${(props) => props.height}px - 82px) !important;

      & img {
        height: calc(${(props) => props.height}px - 82px) !important;
      }
    }

    &.portrait-image {
      & .gatsby-image-wrapper {
        & img {
          object-fit: cover !important;
        }
      }
    }
  }
`;

const SlideshowContainer = styled.div`
  position: relative;
  overflow: hidden;

  & .gatsby-image-wrapper-constrained {
    width: 100%;
  }

  & .fp-slidesNav {
    & ul {
      grid-template-columns: repeat(${(props) => props.numberOfSlides}, 1fr);
    }
  }

  &.active.fp-completely {
    & .fp-slidesNav {
      display: block;
    }
  }
`;

const Caption = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;

  padding: 20px 30px;
  min-height: 102px;

  display: grid;
  grid-template-columns: 150px 1fr 150px;
  align-items: flex-end;

  background-color: #eeeeee;

  text-align: center;

  & p {
    margin: 0;
  }

  & button {
    transition: 250ms color ease;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: #999999;
      }
    }
  }

  & .column {
    &.left {
      text-align: left;
    }

    &.right {
      text-align: right;
    }
  }

  @media (max-width: 860px) {
    font-size: 15px;
    line-height: 21px;

    padding: 20px 10px;
    min-height: 82px;
  }

  @media (max-width: 600px) {
    grid-template-columns: 50px 1fr 50px;
    grid-column-gap: 10px;
  }
`;

const NextArtworkArrow = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;

  height: 100%;
  width: ${(props) => props.width}vw;
  /* width: 100vw; */

  z-index: 10;

  cursor: s-resize;

  cursor: url(/icons/arrow-down@1x.png) 9 9, s-resize !important;
  cursor: -webkit-image-set(
        url(/icons/arrow-down@1x.png) 1x,
        url(/icons/arrow-down@2x.png) 2x
      )
      9 9,
    s-resize !important;

  &.active {
    display: block;
  }

  &.hidden {
    display: none;
  }

  @media (hover: none) and (pointer: coarse) {
    display: none;

    &.active,
    &.hidden {
      display: none;
    }
  }
`;

const PreviousArtworkArrow = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  height: 100%;
  width: ${(props) => props.width}vw;

  z-index: 10;

  cursor: n-resize;

  cursor: url(/icons/arrow-up@1x.png) 9 9, n-resize !important;
  cursor: -webkit-image-set(
        url(/icons/arrow-up@1x.png) 1x,
        url(/icons/arrow-up@2x.png) 2x
      )
      9 9,
    n-resize !important;

  &.active {
    display: block;
  }

  &.hidden {
    display: none;
  }

  @media (hover: none) and (pointer: coarse) {
    display: none;

    &.active,
    &.hidden {
      display: none;
    }
  }
`;

const CloseButton = styled.button`
  position: fixed;
  top: 0;
  right: 0;

  padding: 30px;

  z-index: 100;

  & path {
    transition: 250ms stroke ease;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      & svg {
        & path {
          stroke: #999999;
        }
      }
    }
  }

  @media (max-width: 600px) {
    padding: 10px;

    & svg {
      width: 12px;
      height: auto;
    }
  }
`;

// const Overlay = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   bottom: 0;
//   right: 0;

//   /* cursor: ${(props) => props.cursor}; */
//   z-index: 9;

//   /* z-index: ${(props) => props.zIndex}; */
//   /* opacity: 0; */
//   /* visibility: hidden; */
// `;

Modal.setAppElement("#___gatsby");

const SlideshowArtworksContent = ({
  artworks,
  artist,
  currentOuterSlide,
  currentInnerSlide,
  fullpageApi,
  staticWindowHeight,
  pageType,
}) => {
  const slideshowArtworks = artworks.map((artwork, index) => {
    const slides = artwork.items
      .filter((image) => image.image.gatsbyImageData !== null)
      .map((image, innerIndex) => (
        <SingleSlide
          className={`slide ${
            image.image.dimensions.width > image.image.dimensions.height
              ? `landscape-image`
              : `portrait-image`
          }`}
          key={`single_slide_${innerIndex}_${artwork.id}`}
          height={staticWindowHeight}
        >
          <PreviousArtworkArrow
            onClick={() => fullpageApi.moveSectionUp()}
            type="button"
            width={
              currentInnerSlide === 0 &&
              currentOuterSlide === artworks.length - 1
                ? `100`
                : `50`
            }
            className={
              (currentInnerSlide === 0 && currentOuterSlide !== 0) ||
              (currentInnerSlide === 0 &&
                currentOuterSlide === artworks.length - 1)
                ? `active`
                : `hidden`
            }
          />

          <GalleryImage
            image={image.image.gatsbyImageData}
            alt={`${artist.text}, ${artworks[currentOuterSlide].items[currentInnerSlide].image_title.text}`}
            index={index}
            pageType={pageType}
          />

          <NextArtworkArrow
            onClick={() => fullpageApi.moveSectionDown()}
            type="button"
            width={currentInnerSlide === 0 ? `100` : `50`}
            className={
              currentInnerSlide === artwork.items.length - 1 &&
              currentOuterSlide !== artworks.length - 1
                ? `active`
                : `hidden`
            }
          />
        </SingleSlide>
      ));

    return (
      <SlideshowContainer
        className={`section ${index === 0 ? `active` : ``}`}
        key={`single_slideshow_container_${index}_${artwork.id}`}
        numberOfSlides={slides.length}
      >
        {slides}
      </SlideshowContainer>
    );
  });

  return slideshowArtworks;
};

export const ArtworksLightbox = ({
  artworks,
  artist,
  setIsLightboxOpen,
  staticWindowHeight,
  isLightboxOpen,
  pageType,
}) => {
  const [currentOuterSlide, setCurrentOuterSlide] = useState(0);
  const [currentInnerSlide, setCurrentInnerSlide] = useState(0);
  const [outerFullpageApi, setOuterFullpageApi] = useState(null);
  const [isArtworkEnquiryFormOpen, setIsArtworkEnquiryFormOpen] =
    useState(false);
  const [ref, { x, y, width, height, top, right, bottom, left }] = useMeasure();

  return (
    <RemoveScroll>
      <Modal
        isOpen={isLightboxOpen}
        portalClassName={`ReactModalPortal-artworks-lightbox`}
        className="lightbox-modal"
        contentLabel={`Artworks Lightbox`}
        shouldFocusAfterRender={false}
      >
        <FullPageContainer minHeight={height}>
          <CloseButton
            type="button"
            onClick={() => {
              outerFullpageApi.destroy("all");
              setIsLightboxOpen(false);
            }}
          >
            <CloseIcon />
          </CloseButton>

          <ReactFullpage
            loopHorizontal={false}
            resetSliders={true}
            slidesNavigation={true}
            credits={false}
            // licenseKey={"YOUR_KEY_HERE"}
            touchSensitivity={3}
            scrollingSpeed={1000}
            beforeLeave={(origin, destination, direction, trigger) => {
              setCurrentInnerSlide(0);
            }}
            onLeave={(origin, destination, direction, trigger) => {
              setCurrentOuterSlide(destination.index);
            }}
            onSlideLeave={(
              section,
              origin,
              destination,
              direction,
              trigger
            ) => {
              setCurrentInnerSlide(destination.index);
            }}
            render={({ state, fullpageApi }) => {
              setOuterFullpageApi(fullpageApi);

              return (
                <ReactFullpage.Wrapper>
                  <SlideshowArtworksContent
                    artworks={artworks}
                    artist={artist}
                    currentOuterSlide={currentOuterSlide}
                    currentInnerSlide={currentInnerSlide}
                    fullpageApi={fullpageApi}
                    staticWindowHeight={staticWindowHeight}
                    pageType={pageType}
                  />
                </ReactFullpage.Wrapper>
              );
            }}
          />

          <Caption ref={ref}>
            <div className="column left">
              <p>
                <span>{currentOuterSlide + 1}</span> /{" "}
                <span>{artworks.length}</span>
              </p>
            </div>

            <div className="column middle">
              <PrismicRichText
                field={artist.richText}
                components={{
                  heading2: ({ children }) => (
                    <span className="">{children}, </span>
                  ),
                }}
              />
              <PrismicRichText
                field={
                  artworks[currentOuterSlide].items[currentInnerSlide]
                    .image_title.richText
                }
                components={{
                  paragraph: ({ children }) => (
                    <span className="">{children}</span>
                  ),
                }}
              />

              <PrismicRichText
                field={
                  artworks[currentOuterSlide].items[currentInnerSlide].caption
                    .richText
                }
              />
            </div>

            <div className="column right">
              <button
                type="button"
                onClick={() => setIsArtworkEnquiryFormOpen(true)}
              >
                Enquire
              </button>
            </div>
          </Caption>
        </FullPageContainer>

        {isArtworkEnquiryFormOpen && (
          <ArtworkEnquiryForm
            artist={artist}
            artwork={
              artworks[currentOuterSlide].items[currentInnerSlide].image_title
            }
            url={window.location.href}
            setIsArtworkEnquiryFormOpen={setIsArtworkEnquiryFormOpen}
          />
        )}
      </Modal>
    </RemoveScroll>
  );
};
