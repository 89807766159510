import React, { useState } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Utils
import { dynamicSort } from "../components/utils/dynamic-sort";

// SEO
import { PageSeo } from "../components/seo/page-seo";

// Components
import { ReferencesLightbox } from "../components/images/references-lightbox";
import { Close, Open } from "../components/icons/menu-toggle";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

const Page = styled.div`
  padding: 250px 30px 50px 30px;

  display: grid;
  grid-template-columns: 1fr 910px 1fr;
  grid-column-gap: 30px;

  & .column-1 {
    grid-column: 1;
  }

  & .column-2 {
    grid-column: 2;
  }

  & ol.library-items {
    & li {
      margin: 0 0 1em 0;

      & p {
        margin: 0;

        & a,
        & button {
          color: rgba(153, 153, 153, 1);
          font-style: italic;

          transition: 250ms color ease;

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              color: #000;
            }
          }
        }
      }
    }
  }

  & .filter-heading {
    width: 120px;
    text-align: left;
    display: flex;
    justify-content: space-between;

    letter-spacing: 0.05em;
    line-height: inherit;

    & span {
      line-height: 20px;
    }
  }

  & ol.sort-items {
    margin: 1em 0 0 0;

    & li {
      transition: 250ms color ease;

      & button {
        line-height: inherit;
      }

      &.active {
        color: rgba(153, 153, 153, 1);
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: rgba(153, 153, 153, 1);
        }
      }
    }
  }

  @media (max-width: 860px) {
    padding: 120px 20px 20px 20px;

    display: block;
    grid-template-columns: unset;
    grid-column-gap: unset;

    & .filter-heading {
      font-size: 15px;
      line-height: 21px;

      width: 80px;

      & svg {
        width: 9px;
        height: auto;
      }
    }

    & ol.sort-items {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      margin: 0 0 1em 0;

      & li {
        font-size: 15px;
        line-height: 21px;

        &::after {
          content: ", ";
          padding: 0 2px 0 0;
        }

        &:last-of-type {
          &::after {
            content: "";
          }
        }
      }
    }

    & .content-container {
      padding: 1em 0 0 40px;
    }
  }
`;

const Library = ({ data }) => {
  const [activeFilterArtist, setActiveFilterArtist] = useState(`all`);
  const [activeReference, setActiveReference] = useState(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 861px)");

  const content = data.prismicLibrary.data.body
    .filter((item) => {
      if (activeFilterArtist === `all`) {
        return item;
      } else {
        if (item.primary.selected_by.text.trim() === activeFilterArtist) {
          return item;
        }
      }
    })
    .map((item, index) => (
      <li key={`single_${index}_${item.id}`}>
        <p>
          <PrismicRichText
            field={item.primary.item_author.richText}
            components={{
              paragraph: ({ children }) => (
                <span className="">{children}, </span>
              ),
              hyperlink: ({ node, children }) => {
                if (node.data.link_type === `Media`) {
                  return (
                    <button
                      onClick={() => {
                        if (isDesktop) {
                          setActiveReference(node.data.url);
                        }
                      }}
                      className="reference-button mid-grey"
                    >
                      {node.text}
                    </button>
                  );
                } else {
                  return children;
                }
              },
            }}
          />
          <PrismicRichText
            field={item.primary.item_title.richText}
            components={{
              paragraph: ({ children }) => (
                <span className="">{children}, </span>
              ),
              hyperlink: ({ node, children }) => {
                if (node.data.link_type === `Media`) {
                  return (
                    <button
                      onClick={() => {
                        if (isDesktop) {
                          setActiveReference(node.data.url);
                        }
                      }}
                      className="reference-button mid-grey"
                    >
                      {node.text}
                    </button>
                  );
                } else {
                  return children;
                }
              },
            }}
          />
          <span>{item.primary.item_year}</span>
        </p>

        <p>
          <PrismicRichText
            field={item.primary.medium.richText}
            components={{
              paragraph: ({ children }) => (
                <span className="">{children}, </span>
              ),
              hyperlink: ({ node, children }) => {
                if (node.data.link_type === `Media`) {
                  return (
                    <button
                      onClick={() => {
                        if (isDesktop) {
                          setActiveReference(node.data.url);
                        }
                      }}
                      className="reference-button mid-grey"
                    >
                      {node.text}
                    </button>
                  );
                } else {
                  return children;
                }
              },
            }}
          />
          <PrismicRichText
            field={item.primary.selected_by.richText}
            components={{
              paragraph: ({ children }) => <span className="">{children}</span>,
              hyperlink: ({ node, children }) => {
                if (node.data.link_type === `Media`) {
                  return (
                    <button
                      onClick={() => {
                        if (isDesktop) {
                          setActiveReference(node.data.url);
                        }
                      }}
                      className="reference-button mid-grey"
                    >
                      selected by {node.text}.
                    </button>
                  );
                } else {
                  return `selected by ${node.text}.`;
                }
              },
            }}
          />

          <PrismicRichText
            field={item.primary.link.richText}
            components={{
              paragraph: ({ children }) => (
                <span className=""> {children}</span>
              ),
            }}
          />
        </p>
      </li>
    ));

  const selectedBy = Array.from(
    new Set(
      data.prismicLibrary.data.body
        .filter((item) => item.primary.selected_by.text.length > 0)
        .map((item) => item.primary.selected_by.text.trim())
        .sort()
    )
  ).map((item, index) => (
    <li
      key={`single_author_${index}`}
      className={activeFilterArtist === item ? `active` : ``}
    >
      <button type="button" onClick={() => setActiveFilterArtist(item)}>
        {item}
      </button>
    </li>
  ));

  return (
    <>
      <PageSeo
        seoTitle={data.prismicLibrary.data.title.text}
        seoText={null}
        seoImage={null}
      />

      <Page className="">
        <div className="sort-container column-1">
          <button
            className="filter-heading uppercase"
            type="button"
            onClick={() => setIsFilterOpen(!isFilterOpen)}
          >
            {/* Filter <span>{isFilterOpen ? `↑` : `↓`}</span> */}
            Filter <span>{isFilterOpen ? <Close /> : <Open />}</span>
          </button>

          {isFilterOpen && (
            <ol className="sort-items">
              <li className={activeFilterArtist === `all` ? `active` : ``}>
                <button
                  type="button"
                  onClick={() => setActiveFilterArtist(`all`)}
                >
                  All
                </button>
              </li>
              {selectedBy}
            </ol>
          )}
        </div>

        <div className="content-container column-2">
          <ol className="library-items">{content}</ol>
        </div>
      </Page>

      {activeReference !== null && isDesktop && (
        <ReferencesLightbox
          image={activeReference}
          setActiveReference={setActiveReference}
        />
      )}
    </>
  );
};

export default withPrismicPreview(Library);

export const query = graphql`
  query {
    prismicLibrary {
      data {
        title {
          text
        }
        body {
          ... on PrismicLibraryDataBodyLibraryItem {
            id
            slice_type
            primary {
              item_author {
                richText
                text
              }
              item_title {
                richText
                text
              }
              item_year
              medium {
                richText
                text
              }
              selected_by {
                richText
                text
              }
              link {
                richText
              }
            }
          }
        }
      }
    }
  }
`;
