import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";
import { PrismicRichText } from "@prismicio/react";
import { GatsbyImage } from "gatsby-plugin-image";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Utils
import kebabCase from "lodash.kebabcase";

// SEO
import { PageSeo } from "../components/seo/page-seo";

const Page = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: ${(props) => props.height}px;
  overflow: hidden;

  & a {
    z-index: 1;
  }

  & .title-container {
    z-index: 100;

    text-align: center;
    opacity: 1;

    transition: 250ms color ease;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: #999999;
      }
    }

    & h1 {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;

      text-align: center;

      font-size: 44px;
      line-height: 50px;
      min-height: 100px;

      margin: 15px 0;

      & span {
        display: block;
        width: 100%;
      }
    }

    & p {
      margin: 0;
    }
  }

  & .image-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    opacity: 0;
    z-index: 0;

    transition: 1000ms opacity ease-in-out;

    & .gatsby-image-wrapper {
      height: ${(props) => props.height}px !important;

      & img {
        height: ${(props) => props.height}px !important;
        object-fit: cover;
      }
    }
  }

  & a:hover + .image-container {
    opacity: 1;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  /* grid-column-gap: 90px; */
  width: 100%;

  @media (max-width: 1300px) {
    /* grid-column-gap: 60px; */
  }

  @media (max-width: 1000px) {
    /* grid-column-gap: 40px; */
  }

  @media (max-width: 860px) {
    display: block;
    grid-template-columns: unset;
    /* grid-column-gap: unset; */

    & .single-current-item-container {
      padding: 50px 20px;

      & .title-container {
        & h1 {
          min-height: auto;

          font-size: 30px;
          line-height: 40px;

          margin: 5px 0;
        }
      }
    }
  }
`;

const Index = ({ data }) => {
  // Window Height
  const height = use100vh();

  const content = data.prismicHome.data.current
    .filter((item) => item.current_item.document !== null)
    .map((item, index) => {
      if (item.current_item.document.type === `news_article`) {
        return (
          <div className="single-current-item-container news">
            <Link
              to={`/news#${item.current_item.document.uid}`}
              state={{ article: item.current_item.document.uid }}
            >
              <div className="title-container" data-nosnippet>
                <p className="" data-nosnippet>
                  {item.top_line}
                </p>
                <h1>
                  <PrismicRichText
                    field={item.current_item.document.data.title.richText}
                    components={{
                      heading1: ({ children }) => (
                        <span className="exhibition-title" data-nosnippet>
                          <em>{children}</em>
                        </span>
                      ),
                    }}
                  />
                </h1>

                <p data-nosnippet>
                  {item.current_item.document.data.published_date}
                </p>
              </div>
            </Link>
          </div>
        );
      }

      if (item.current_item.document.type === `exhibition`) {
        return (
          <div className="single-current-item-container exhibition">
            <Link to={item.current_item.document.url}>
              <div className="title-container">
                <p className="" data-nosnippet>
                  {item.top_line}
                </p>
                <h1 data-nosnippet>
                  <PrismicRichText
                    field={item.current_item.document.data.artist.richText}
                    components={{
                      heading2: ({ children }) => (
                        <span className="artist-title" data-nosnippet>
                          {children}
                        </span>
                      ),
                    }}
                  />

                  <PrismicRichText
                    field={item.current_item.document.data.title.richText}
                    components={{
                      heading1: ({ children }) => (
                        <span className="exhibition-title" data-nosnippet>
                          <em>{children}</em>
                        </span>
                      ),
                    }}
                  />
                </h1>

                <PrismicRichText
                  data-nosnippet
                  field={
                    item.current_item.document.data.additional_info.richText
                  }
                />

                <p data-nosnippet>
                  {item.current_item.document.data.start_date}
                  {" – "}
                  {item.current_item.document.data.end_date}
                </p>
              </div>
            </Link>
          </div>
        );
      }

      if (item.current_item.document.type === `residency`) {
        return (
          <div className="single-current-item-container residency">
            <Link to={item.current_item.document.url}>
              <div className="title-container" data-nosnippet>
                <p className="" data-nosnippet>
                  {item.top_line}
                </p>
                <h1 data-nosnippet>
                  <PrismicRichText
                    field={item.current_item.document.data.artist.richText}
                    components={{
                      heading2: ({ children }) => (
                        <span className="artist-title">{children}</span>
                      ),
                    }}
                  />

                  <PrismicRichText
                    field={item.current_item.document.data.title.richText}
                    components={{
                      heading1: ({ children }) => (
                        <span className="exhibition-title" data-nosnippet>
                          <em>{children}</em>
                        </span>
                      ),
                    }}
                  />
                </h1>

                <p data-nosnippet>
                  {item.current_item.document.data.start_date}
                  {" – "}
                  {item.current_item.document.data.end_date}
                </p>
              </div>
            </Link>
          </div>
        );
      }
    });

  return (
    <>
      <PageSeo seoTitle={null} seoText={null} seoImage={null} />

      <Page height={height}>
        <Grid columns={content.length}>{content}</Grid>
      </Page>
    </>
  );
};

export default withPrismicPreview(Index);

export const query = graphql`
  query {
    prismicHome {
      url
      data {
        title {
          text
        }
        current {
          top_line
          current_item {
            document {
              ... on PrismicNewsArticle {
                id
                url
                uid
                type
                data {
                  title {
                    richText
                    text
                  }
                  published_date(formatString: "DD.MM.YY")
                }
              }
              ... on PrismicResidency {
                id
                url
                type
                data {
                  title {
                    richText
                  }
                  artist {
                    richText
                  }
                  start_date(formatString: "DD.MM.YY")
                  end_date(formatString: "DD.MM.YY")
                }
              }
              ... on PrismicExhibition {
                id
                url
                type
                data {
                  title {
                    richText
                  }
                  artist {
                    richText
                  }
                  additional_info {
                    richText
                  }
                  start_date(formatString: "DD.MM.YY")
                  end_date(formatString: "DD.MM.YY")
                }
              }
            }
          }
        }
      }
    }
  }
`;
