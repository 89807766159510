import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { PrismicRichText } from "@prismicio/react";
import { use100vh } from "react-div-100vh";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Utils
import { FormatDate } from "../components/utils/format-date";

// SEO
import { PageSeo } from "../components/seo/page-seo";

const Page = styled.div`
  padding: 90px 30px 0 30px;

  display: grid;
  grid-template-columns: 1fr 910px 1fr;
  grid-column-gap: 30px;

  & .column-2 {
    grid-column: 2;
  }

  & .content-container {
    min-height: calc(${(props) => props.height}px - 100px);
  }

  & .all-residencies-container {
    padding: 250px 0 0 0;
  }

  & .image-container {
    max-width: 675px;
  }

  & .text-container {
    max-width: 910px;
    margin: 30px 0;

    & p {
      margin: 0;
      text-indent: 50px;

      &:first-of-type {
        text-indent: 0;
      }
    }
  }

  & .residency-container {
    margin: 0 0 2em 30px;

    & h1 {
      margin: 0 0 1em 0;
    }

    & ol {
      & li {
        margin: 0 0 1em 0;

        & h1,
        & p {
          margin: 0;
        }
      }
    }
  }

  @media (max-width: 860px) {
    padding: 120px 20px 20px 20px;

    display: block;
    grid-template-columns: unset;
    grid-column-gap: unset;

    & .column-2 {
      grid-column: unset;
    }

    & .residency-container {
      margin: 0 0 2em 40px;
    }
  }
`;

const Residencies = ({ data }) => {
  // Window Height
  const height = use100vh();

  const content = data.prismicResidencies.data.body.map((content, index) => {
    if (content.slice_type === `text`) {
      return (
        <div
          key={`single_section_${index}_${content.id}`}
          className="row text-container"
        >
          <PrismicRichText field={content.primary.text.richText} />
        </div>
      );
    }

    if (content.slice_type === `image`) {
      return (
        <div
          key={`single_section_${index}_${content.id}`}
          className="row image-container"
        >
          <GatsbyImage
            image={content.primary.image.gatsbyImageData}
            alt={
              content.primary.image.alt !== null
                ? content.primary.image.alt
                : ``
            }
          />
        </div>
      );
    }
  });

  const currentResidency = data.prismicResidencies.data.current_residency
    .filter((residency) => residency.residency.document !== null)
    .map((residency, index) => (
      <li key={`single_current_residency_${index}`}>
        <Link to={residency.residency.document.url}>
          <h1>
            <PrismicRichText
              field={residency.residency.document.data.artist.richText}
              components={{
                heading2: ({ children }) => (
                  <span className="">{children}, </span>
                ),
              }}
            />

            <PrismicRichText
              field={residency.residency.document.data.title.richText}
              components={{
                heading1: ({ children }) => (
                  <span className="italic">{children}</span>
                ),
              }}
            />
          </h1>

          <p>
            {FormatDate(
              residency.residency.document.data.start_date,
              residency.residency.document.data.end_date
            )}
          </p>
        </Link>
      </li>
    ));

  const pastResidency = data.prismicResidencies.data.past_residency
    .filter((residency) => residency.residency.document !== null)
    .map((residency, index) => (
      <li key={`single_past_residency_${index}`}>
        <Link to={residency.residency.document.url}>
          <h1>
            <PrismicRichText
              field={residency.residency.document.data.artist.richText}
              components={{
                heading2: ({ children }) => (
                  <span className="">{children}, </span>
                ),
              }}
            />

            <PrismicRichText
              field={residency.residency.document.data.title.richText}
              components={{
                heading1: ({ children }) => (
                  <span className="italic">{children}</span>
                ),
              }}
            />
          </h1>

          <p>
            {FormatDate(
              residency.residency.document.data.start_date,
              residency.residency.document.data.end_date
            )}
          </p>
        </Link>
      </li>
    ));

  return (
    <>
      <PageSeo
        seoTitle={data.prismicResidencies.data.title.text}
        seoText={null}
        seoImage={null}
      />

      <Page className="grid" height={height}>
        <section className="content-container column-2">{content}</section>

        <section className="all-residencies-container content-container column-2">
          {currentResidency.length > 0 && (
            <div className="residency-container column-2">
              <h1 className="uppercase">Current</h1>
              <ol>{currentResidency}</ol>
            </div>
          )}

          {pastResidency.length > 0 && (
            <div className="residency-container column-2">
              <h1 className="uppercase">Past</h1>
              <ol>{pastResidency}</ol>
            </div>
          )}
        </section>
      </Page>
    </>
  );
};

export default withPrismicPreview(Residencies);

export const query = graphql`
  query {
    prismicResidencies {
      data {
        title {
          text
        }
        current_residency {
          residency {
            document {
              ... on PrismicResidency {
                id
                data {
                  title {
                    richText
                  }
                  artist {
                    richText
                  }
                  start_date
                  end_date
                }
                url
              }
            }
          }
        }
        past_residency {
          residency {
            document {
              ... on PrismicResidency {
                id
                data {
                  title {
                    richText
                  }
                  artist {
                    richText
                  }
                  start_date
                  end_date
                }
                url
              }
            }
          }
        }
        body {
          ... on PrismicResidenciesDataBodyImage {
            id
            slice_type
            primary {
              image {
                alt
                gatsbyImageData
              }
            }
          }
          ... on PrismicResidenciesDataBodyText {
            id
            slice_type
            primary {
              text {
                richText
              }
            }
          }
        }
      }
    }
  }
`;
