import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useLockBodyScroll, useToggle } from "react-use";
import { use100vh } from "react-div-100vh";
import Modal from "react-modal";
import { RemoveScroll } from "react-remove-scroll";

// Icons
// import { CloseIcon } from "../icons/close-icons";

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: ${(props) => props.height}px;

  &.exhibition {
    cursor: url(/icons/close@1x.png) 9 9, crosshair !important;
    cursor: -webkit-image-set(
          url(/icons/close@1x.png) 1x,
          url(/icons/close@2x.png) 2x
        )
        9 9,
      crosshair !important;
  }

  &.residency {
    cursor: url(/icons/close-white@1x.png) 9 9, crosshair !important;
    cursor: -webkit-image-set(
          url(/icons/close-white@1x.png) 1x,
          url(/icons/close-white@2x.png) 2x
        )
        9 9,
      crosshair !important;
  }

  & img {
    max-width: 580px;
    max-height: 600px;

    height: 100%;
    width: 100%;
    object-fit: contain;

    @media (max-height: 700px) {
      max-height: 400px;
    }
  }
`;

// const CloseButton = styled.button`
//   display: none;

//   position: fixed;
//   top: 0;
//   right: 0;

//   padding: 30px;

//   z-index: 100;

//   & path {
//     transition: 250ms stroke ease;
//   }

//   @media (hover: hover) and (pointer: fine) {
//     &:hover {
//       & svg {
//         & path {
//           stroke: #999999;
//         }
//       }
//     }
//   }

//   @media (max-width: 600px) {
//     display: block;
//     padding: 10px;

//     & svg {
//       width: 12px;
//       height: auto;
//     }
//   }
// `;

Modal.setAppElement("#___gatsby");

export const ReferencesLightbox = ({ image, setActiveReference, pageType }) => {
  // Window Height
  const height = use100vh();

  // Scroll lock
  // const [locked, toggleLocked] = useToggle(false);
  // useLockBodyScroll(locked);

  return (
    <RemoveScroll>
      <Modal
        isOpen={true}
        portalClassName={`ReactModalPortal-lightbox`}
        className="lightbox-modal"
        contentLabel={`Lightbox`}
        shouldFocusAfterRender={false}
      >
        <ImageContainer
          height={height}
          onClick={() => setActiveReference(null)}
          className={pageType}
        >
          <img src={image} alt={""} />

          {/* <CloseButton type="button" onClick={() => setActiveReference(null)}>
            <CloseIcon />
          </CloseButton> */}
        </ImageContainer>
      </Modal>
    </RemoveScroll>
  );
};
