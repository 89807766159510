import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";
import { PrismicRichText } from "@prismicio/react";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Components
import { InfoMailingList } from "../components/forms/info-mailchimp";

// SEO
import { PageSeo } from "../components/seo/page-seo";

const Page = styled.div`
  padding: 250px 30px 50px 30px;

  display: grid;
  grid-template-columns: 1fr 910px 1fr;
  grid-column-gap: 30px;

  & .column-1 {
    grid-column: 1;
  }

  & .column-2 {
    grid-column: 2;
  }

  & .content-container {
    /* display: grid; */
    /* grid-template-columns: 1fr 910px 1fr; */

    & .row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      min-height: ${(props) => props.height}px;

      &.center {
        padding: calc(100px - 1em) 0 0 0;
        /* align-items: center; */
      }

      &.start {
        /* align-items: flex-start; */

        & p {
          margin: 0;
          text-indent: 50px;

          &:first-of-type {
            text-indent: 0;
          }
        }
      }

      & .text-container {
        height: 100%;

        & a {
          transition: 250ms color ease;
        }
      }

      &.newsletter {
        /* min-height: 100%; */
        /* min-height: calc(${(props) => props.height}px / 2); */
      }
    }
  }

  & .list {
    position: sticky;
    top: 100px;

    & ol {
      /* margin: 1em 0 0 0; */

      & li {
        list-style: decimal;

        margin: 0 0 0 30px;

        transition: 250ms color ease;

        &.active {
          color: rgba(153, 153, 153, 1);
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            color: rgba(153, 153, 153, 1);
          }
        }
      }
    }
  }

  & .bottom-text-container {
    & p {
      & a {
        color: #000;
      }
    }
  }

  @media (max-width: 860px) {
    padding: 120px 20px 20px 20px;

    display: block;
    grid-template-columns: unset;
    grid-column-gap: unset;

    & .column-1,
    & .column-2 {
      grid-column: unset;
    }

    & .content-container {
      & .row {
        /* min-height: unset; */
        min-height: calc(${(props) => props.height}px / 2);
      }
    }

    & .list {
      position: relative;
      top: 0;

      margin: 0 0 1em 0;

      & ol {
        & li {
          margin: 0 0 0 20px;
        }
      }
    }
  }
`;

const Info = ({ data }) => {
  // Window Height
  const height = use100vh();

  const titles = data.prismicInfo.data.body.map((content, index) => (
    <li key={`single_title_${index}_${content.id}`}>
      <button
        onClick={() =>
          document.querySelector(`#row-${index + 1}`).scrollIntoView({
            behavior: "smooth",
            block: "start",
          })
        }
      >
        {content.primary.section_title.text}
      </button>
    </li>
  ));

  const content = data.prismicInfo.data.body.map((content, index) => {
    if (content.slice_type === `text_section`) {
      return (
        <>
          {index !== 0 && <div id={`row-${index + 1}`} />}
          <div
            key={`single_section_${index}_${content.id}`}
            className={`row ${index === 0 ? `start` : `center`}`}
          >
            <div className="text-container">
              <PrismicRichText field={content.primary.section_text.richText} />
            </div>
          </div>
        </>
      );
    }

    if (content.slice_type === `newsletter`) {
      return (
        <>
          <div id={`row-${index + 1}`} />
          <div
            key={`single_section_${index}_${content.id}`}
            className="row center newsletter"
          >
            <div className="text-container">
              <PrismicRichText field={content.primary.signup_text.richText} />

              <div className="mailchimp-container">
                <InfoMailingList />
              </div>

              <PrismicRichText field={content.primary.cookie_text.richText} />
            </div>
          </div>
        </>
      );
    }
  });

  return (
    <>
      <PageSeo
        seoTitle={data.prismicInfo.data.title.text}
        seoText={null}
        seoImage={null}
      />

      <div id="row-1" />
      <Page height={height}>
        <section className="content-container column-1">
          <div className="list">
            <ol>{titles}</ol>
          </div>
        </section>

        <section className="content-container column-2">
          <div>{content}</div>
        </section>

        <section className="content-container column-1" />

        <section className="content-container column-2">
          <div className="bottom-text-container small-text">
            <PrismicRichText
              field={data.prismicInfo.data.bottom_text.richText}
            />
          </div>
        </section>
      </Page>
    </>
  );
};

export const query = graphql`
  query {
    prismicInfo {
      data {
        title {
          text
        }
        body {
          ... on PrismicInfoDataBodyTextSection {
            id
            slice_type
            primary {
              section_title {
                text
              }
              section_text {
                richText
              }
            }
          }
          ... on PrismicInfoDataBodyNewsletter {
            id
            slice_type
            primary {
              section_title {
                text
              }
              signup_text {
                richText
              }
              cookie_text {
                richText
              }
            }
          }
        }
        bottom_text {
          richText
        }
      }
    }
  }
`;

export default withPrismicPreview(Info);
