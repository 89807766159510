import React from "react";
import {
  TransitionGroup,
  Transition as ReactTransition,
} from "react-transition-group";

//https://builtbydamian.com/blog/gatsby-page-transitions/

// The duration for each phase of the transition
// So the total duration will be _twice_ this

export const Transition = ({ children, location }) => {
  let timeout = 400;

  const styles = {
    entering: {
      position: "absolute",
      opacity: 0,
    },
    entered: {
      transition: `opacity ${timeout}ms ease-in-out`,
      opacity: 1,
    },
    exiting: {
      transition: `opacity ${timeout}ms ease-in-out`,
      opacity: 0,
    },
  };

  return (
    <TransitionGroup>
      <ReactTransition key={location.pathname} timeout={timeout}>
        {(status) => {
          return (
            <div style={styles[status]} className={`animation-${status}`}>
              {children}
            </div>
          );
        }}
      </ReactTransition>
    </TransitionGroup>
  );
};
