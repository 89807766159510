exports.linkResolver = (doc) => {
  switch (doc.type) {
    case "home": {
      return `/`;
    }

    case "archive": {
      return `/${doc.uid}/`;
    }

    case "exhibition": {
      return `/exhibition/${doc.uid}/`;
    }

    case "info": {
      return `/${doc.uid}/`;
    }

    case "library": {
      return `/${doc.uid}/`;
    }

    case "news": {
      return `/${doc.uid}/`;
    }

    case "news_article": {
      return `/news/${doc.uid}/`;
    }

    case "residencies": {
      return `/${doc.uid}/`;
    }

    case "residency": {
      return `/residency/${doc.uid}/`;
    }

    case "privacy_policy": {
      return `/${doc.uid}/`;
    }
  }
};
