import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";
import { useWindowScroll } from "react-use";
import { use100vh } from "react-div-100vh";
import { GatsbyImage } from "gatsby-plugin-image";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Components
import { ReferencesLightbox } from "../components/images/references-lightbox";
import { FloatingNavigation } from "../components/navigation/floating-navigation";

// Utils
import { FormatDate } from "../components/utils/format-date";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

// SEO
import { PageSeo } from "../components/seo/page-seo";
import { ArtworksLightbox } from "../components/images/artworks-lightbox";

const Page = styled.div`
  padding: 0 0 150px 0;

  & .hero-container {
    max-height: calc(${(props) => props.height}px - 100px);
    height: 100%;

    overflow: hidden;

    & .gatsby-image-wrapper-constrained {
      width: 100%;
    }

    & img {
      max-height: calc(${(props) => props.height}px - 100px);
      height: 100%;
    }
  }

  & .content-container {
    max-width: 910px;

    margin: 0 auto;
    padding: 120px 30px 0 30px;
  }

  & .text-container {
    & p {
      margin: 0;

      text-indent: 50px;

      &:first-of-type {
        text-indent: 0;
      }

      & strong {
        display: block;
        text-transform: uppercase;
        letter-spacing: 0.05em;

        margin: 3em 0 1em 0;
      }

      & .reference-button {
        transition: 250ms color ease;

        text-align: left;

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            color: #000;
          }
        }
      }
    }
  }

  & .references-container,
  & .press-release-container {
    margin: 2em 0 0 0;

    & ol {
      margin: 1em 0 0 0;

      & li {
        margin: 0 0 1em 0;

        &.margin-0 {
          margin: 0;
        }

        & p {
          margin: 0;

          &.reference-marker {
            text-indent: 30px;
          }

          & .reference-button {
            transition: 250ms color ease;

            white-space: break-spaces;
            text-align: left;
            line-height: 25px;

            @media (hover: hover) and (pointer: fine) {
              &:hover {
                color: #999;
              }
            }
          }

          & a {
            color: #000;
            transition: 250ms color ease;

            @media (hover: hover) and (pointer: fine) {
              &:hover {
                color: #999;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 860px) {
    padding: 120px 0 20px 0;

    & .content-container {
      margin: 0 auto 120px auto;

      padding: 60px 20px 0 20px;
    }
  }
`;

const TitleContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;

  padding: 20px 30px;

  background: #eeeeee;

  text-align: center;

  /* transform: translateY(${(props) => props.translateY}) translateZ(0); */
  /* transition: 300ms transform linear; */

  & p {
    margin: 0;
  }

  @media (max-width: 860px) {
    & h1,
    & p {
      font-size: 15px;
      line-height: 21px;
    }

    padding: 20px;
  }
`;

const Exhibition = ({ data }) => {
  const [activeReference, setActiveReference] = useState(null);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  // const [isTitleVisible, setIsTitleVisible] = useState(true);

  // Get window scroll
  const { x, y } = useWindowScroll();

  // Get window height
  const height = use100vh();

  // Media Query
  let isMobile = useMediaQuery("(max-width: 860px)");

  const [staticWindowHeight, setStaticWindowHeight] = useState(0);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setStaticWindowHeight(window.innerHeight);
    }
  }, []);

  // Header scroll
  useEffect(() => {
    // if (isMobile) {
    //    if (y + 120 >= staticWindowHeight) {
    //      // up
    //      document.querySelector(`header`).classList.add(`active-background`);
    //    } else {
    //      document.querySelector(`header`).classList.remove(`active-background`);
    //    }
    // } else {
    if (y + 120 >= staticWindowHeight) {
      // up
      document.querySelector(`header`).classList.add(`active-background`);
    } else {
      document.querySelector(`header`).classList.remove(`active-background`);
    }
    // }
  }, [y, staticWindowHeight, isMobile]);

  const references = Array.from(
    data.prismicExhibition.data.body.map((item, index) => {
      if (item.slice_type === `text`) {
        return item.primary.text.richText
          .filter((text) => text.spans.length >= 1)
          .flat()
          .map((text) => text.spans.filter((span) => span.type === `hyperlink`))
          .flat();
      } else {
        return [];
      }
    })
  ).flat();

  const referenceStartPositions = references.map(
    (reference) => reference.start
  );

  const inlineReferences = data.prismicExhibition.data.body.filter(
    (item) => item.slice_type === `references`
  );

  const content = data.prismicExhibition.data.body.map((item, index) => {
    if (item.slice_type === `artist_biography`) {
      return (
        <div
          className="artist-biography-container"
          key={`single_artist_biography_${index}_${item.id}`}
        >
          <PrismicRichText field={item.primary.artist_biography.richText} />
        </div>
      );
    }

    if (item.slice_type === `text`) {
      return (
        <div
          className="text-container"
          key={`single_text_container_${index}_${item.id}`}
        >
          <PrismicRichText
            field={item.primary.text.richText}
            components={{
              hyperlink: ({ node, children }) => {
                if (node.data.link_type === `Media`) {
                  const referenceMarker = referenceStartPositions.indexOf(
                    node.start
                  );
                  return (
                    <button
                      onClick={() => setActiveReference(node.data.url)}
                      className="reference-button mid-grey"
                    >
                      {node.text}

                      {referenceMarker !== -1 && (
                        <sup className="reference-marker">
                          {referenceMarker + 1}
                        </sup>
                      )}
                    </button>
                  );
                } else {
                  return children;
                }
              },
              strong: ({ node, children }) => {
                return children;
              },
            }}
          />
        </div>
      );
    }

    if (item.slice_type === `references`) {
      const items = item.items.map((reference, index) => (
        <li key={`single_reference_${index}_${item.id}`} className="small-text">
          <p className="reference-marker">{index + 1}</p>

          <PrismicRichText
            field={reference.reference.richText}
            components={{
              hyperlink: ({ node, children }) => {
                if (node.data.link_type === `Media`) {
                  return (
                    <button
                      onClick={() => setActiveReference(node.data.url)}
                      className="reference-button"
                    >
                      {node.text}
                    </button>
                  );
                } else {
                  return children;
                }
              },
            }}
          />
        </li>
      ));

      return (
        <div
          className="references-container"
          key={`single_reference_container_${index}_${item.id}`}
          id="references-container"
        >
          <h2 className="uppercase small-text">References</h2>

          <ol>{items}</ol>
        </div>
      );
    }
  });

  return (
    <>
      <PageSeo
        seoTitle={`${data.prismicExhibition.data.artist.text}, ${data.prismicExhibition.data.title.text}`}
        seoText={null}
        seoImage={
          data.prismicExhibition.data.desktop_thumbnail.url !== ""
            ? data.prismicExhibition.data.desktop_thumbnail.url
            : null
        }
      />

      <Page height={staticWindowHeight}>
        <section className="hero-container">
          {isMobile ? (
            <>
              {data.prismicExhibition.data.mobile_thumbnail.gatsbyImageData !==
                null && (
                <GatsbyImage
                  image={
                    data.prismicExhibition.data.mobile_thumbnail.gatsbyImageData
                  }
                  alt={`${data.prismicExhibition.data.artist.text}, ${data.prismicExhibition.data.title.text}`}
                  loading="eager"
                  fetchpriority={`high`}
                />
              )}
            </>
          ) : (
            <>
              {data.prismicExhibition.data.desktop_thumbnail.gatsbyImageData !==
                null && (
                <GatsbyImage
                  image={
                    data.prismicExhibition.data.desktop_thumbnail
                      .gatsbyImageData
                  }
                  alt={`${data.prismicExhibition.data.artist.text}, ${data.prismicExhibition.data.title.text}`}
                  loading="eager"
                  fetchpriority={`high`}
                />
              )}
            </>
          )}
        </section>

        <FloatingNavigation
          gallery={true}
          pressRelease={content.length >= 1}
          references={references.length >= 1 || inlineReferences.length >= 1}
          pageType={`exhibition`}
          setIsLightboxOpen={setIsLightboxOpen}
        />

        <section className="content-container" id="content-container">
          <div className="inner-content-container">{content}</div>
        </section>
      </Page>

      <TitleContainer
        className="title-container"
        // translateY={isTitleVisible ? `0` : `100%`}
      >
        <h1>
          <PrismicRichText
            field={data.prismicExhibition.data.artist.richText}
            components={{
              heading2: ({ children }) => (
                <span className="">{children}, </span>
              ),
            }}
          />

          <PrismicRichText
            field={data.prismicExhibition.data.title.richText}
            components={{
              heading1: ({ children }) => (
                <span className="italic">{children}</span>
              ),
            }}
          />
        </h1>

        <PrismicRichText
          field={data.prismicExhibition.data.additional_info.richText}
        />

        <p>
          {FormatDate(
            data.prismicExhibition.data.start_date,
            data.prismicExhibition.data.end_date
          )}
        </p>
      </TitleContainer>

      {isLightboxOpen && (
        <ArtworksLightbox
          artworks={data.prismicExhibition.data.body2}
          artist={data.prismicExhibition.data.artist}
          setIsLightboxOpen={setIsLightboxOpen}
          staticWindowHeight={staticWindowHeight}
          isLightboxOpen={isLightboxOpen}
          pageType={`exhibition`}
        />
      )}

      {activeReference !== null && (
        <ReferencesLightbox
          image={activeReference}
          setActiveReference={setActiveReference}
          pageType={`exhibition`}
        />
      )}

      <form
        type="hidden"
        name="artwork-enquiry"
        method="POST"
        data-netlify="true"
        netlify-honeypot="bot-field"
      >
        <input type="hidden" name="url" />

        <input type="hidden" name="bot-field" />
        <input type="hidden" name="subject" />
        <input type="hidden" name="email" />
      </form>
    </>
  );
};

export default withPrismicPreview(Exhibition);

export const query = graphql`
  query Exhibition($id: String) {
    prismicExhibition(id: { eq: $id }) {
      data {
        title {
          richText
          text
        }
        artist {
          richText
          text
        }
        additional_info {
          richText
        }
        start_date
        end_date
        desktop_thumbnail {
          gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 2000)
          alt
          url
          dimensions {
            width
            height
          }
        }
        mobile_thumbnail {
          gatsbyImageData(layout: FULL_WIDTH)
          alt
          url
          dimensions {
            width
            height
          }
        }
        body {
          ... on PrismicExhibitionDataBodyArtistBiography {
            id
            slice_type
            primary {
              artist_biography {
                richText
              }
            }
          }
          ... on PrismicExhibitionDataBodyImage {
            id
            slice_type
            primary {
              image {
                gatsbyImageData(layout: FULL_WIDTH)
                alt
              }
            }
          }
          ... on PrismicExhibitionDataBodyReferences {
            id
            slice_type
            items {
              reference {
                richText
              }
            }
          }
          ... on PrismicExhibitionDataBodyText {
            id
            slice_type
            primary {
              text {
                richText
              }
            }
          }
          ... on PrismicExhibitionDataBodyVideo {
            id
            slice_type
            primary {
              video {
                id
              }
            }
          }
          ... on PrismicExhibitionDataBodyPressRelease {
            id
            slice_type
            items {
              press_release {
                richText
              }
            }
          }
        }
        body2 {
          ... on PrismicExhibitionDataBody2Artwork {
            id
            slice_type
            items {
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH, srcSetMaxWidth: 2000)
                dimensions {
                  width
                  height
                }
              }
              caption {
                richText
              }
              image_title {
                richText
                text
              }
            }
          }
        }
      }
    }
  }
`;
