import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import CookieConsent from "react-cookie-consent";
import styled from "styled-components";

const CookieContainer = styled.div`
  & .cookie-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 10000;

    padding: 30px;

    display: grid;
    grid-template-columns: auto 197px;
    grid-column-gap: 20px;
    align-items: center;

    background-color: #fff;
    color: #000;

    & p {
      margin: 0;
      color: #000;

      & a {
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 2px;
      }
    }

    & .cookie-text {
      max-width: 1000px;
    }

    & .cookie-container {
      display: inline-block;

      @media (max-width: 860px) {
        margin: 0 10px 0 0;
      }
    }

    & .content-container {
      color: #000;
    }

    & .cookie-text > p {
      margin: 0;
      color: #000;
    }

    & .button-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: end;
    }

    & button {
      display: block;

      width: fit-content;

      padding: 10px 15px;

      color: #000;
      transition: 250ms color ease;

      &.cookie-button {
        order: 1;
      }

      &#rcc-decline-button {
        order: 2;
      }

      @media (max-width: 500px) {
        padding: 0 10px;
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover,
        &:focus,
        &:focus-within {
          color: #999;
        }
      }
    }

    & a.privacy-link {
      border: 1px solid #fff;
      transition: 250ms all ease;

      text-align: center;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.075em;
      padding: 3px 10px;

      transition: 250ms all ease;

      color: #000;

      cursor: pointer;
      display: inline-block;

      @media (max-width: 860px) {
        font-size: 10px;
        padding: 2px 5px;
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover,
        &:focus,
        &:focus-within {
          color: #dcede9;
          background-color: #fff;
        }
      }
    }

    @media (max-width: 860px) {
      padding: 20px;
    }

    @media (max-width: 700px) {
      display: block;
      grid-template-columns: unset;
      grid-column-gap: unset;
      align-items: unset;

      & .button-container {
        margin: 1em 0 0 0;

        & button {
          font-size: 15px;
          line-height: 21px;
        }
      }
    }
  }
`;

export const Cookies = ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      prismicMenu {
        data {
          cookie_text {
            richText
          }
        }
      }
    }
  `);

  return (
    <CookieContainer>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        enableDeclineButton
        declineButtonText="Decline"
        cookieName="ppp-gdpr-google-analytics"
        containerClasses="cookie-container"
        contentClasses="content-container"
        buttonWrapperClasses="button-container"
        buttonClasses="cookie-button"
        disableStyles={true}
        onAccept={() => {
          initializeAndTrack(location);
        }}
        expires={150}
      >
        <div className="cookie-text">
          <p className="small-text">
            We use cookies to make our website work more efficiently, to provide
            you with more personalised services or advertising to you, and to
            analyse traffic on our website. For more information please read our{" "}
            <Link to={`/privacy-policy/`}>cookie & privacy policy</Link>.
          </p>
        </div>
      </CookieConsent>
    </CookieContainer>
  );
};
