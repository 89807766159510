import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { use100vh } from "react-div-100vh";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Utils
import { FormatDate } from "../components/utils/format-date";
import kebabCase from "lodash.kebabcase";

// SEO
import { PageSeo } from "../components/seo/page-seo";
import { Video } from "../components/video/video";
import { useEffect } from "react";

const Page = styled.div`
  padding: 250px 30px 50px 30px;

  & .content-container {
    max-width: 910px;
    margin: 0 auto;
  }

  & article {
    margin: 0 0 200px 0;
    /* min-height: ${(props) => props.height}px; */

    & .title-container {
      margin: 1em 0 1em 30px;
    }

    & .image-container,
    & .video-container {
      max-width: 675px;
    }
  }

  @media (max-width: 860px) {
    padding: 120px 20px 20px 20px;
  }
`;

const News = ({ data, location }) => {
  // Window Height
  const height = use100vh();

  useEffect(() => {
    if (
      location !== undefined &&
      location !== null &&
      location.state !== undefined &&
      location.state !== null &&
      location.state.article !== undefined &&
      location.state.article !== null
    ) {
      const timer = setTimeout(() => {
        const articleToScrollTo = document.getElementById(
          `article-${location.state.article}`
        );

        if (articleToScrollTo !== undefined) {
          articleToScrollTo.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [location]);

  const content = data.allPrismicNewsArticle.nodes.map((article, index) => {
    const innerContent = article.data.body.map((item, index) => {
      if (item.slice_type === `image`) {
        return (
          <div className="image-container">
            {item.primary.image !== null && (
              <GatsbyImage
                image={item.primary.image.gatsbyImageData}
                alt={
                  item.primary.image.alt !== null ? item.primary.image.alt : ``
                }
              />
            )}
          </div>
        );
      }

      if (item.slice_type === `video`) {
        return (
          <div className="video-container">
            <Video video={item.primary.video} id={item.id} />
          </div>
        );
      }

      if (item.slice_type === `text`) {
        return (
          <div className="text-container">
            <PrismicRichText field={item.primary.text.richText} />
          </div>
        );
      }
    });

    return (
      <>
        <div id={`article-${article.uid}`} />
        <article key={`single_article_${index}_${article.id}`}>
          <div className="title-container">
            <PrismicRichText field={article.data.title.richText} />
            {FormatDate(article.data.published_date, null)}
          </div>

          <div className="content">{innerContent}</div>
        </article>
      </>
    );
  });

  return (
    <>
      <PageSeo
        seoTitle={data.prismicNews.data.title.text}
        seoText={null}
        seoImage={null}
      />

      <Page height={height}>
        <div className="content-container">{content}</div>
      </Page>
    </>
  );
};

export default withPrismicPreview(News);

export const query = graphql`
  query {
    prismicNews {
      data {
        title {
          text
        }
      }
    }
    allPrismicNewsArticle(
      sort: { fields: data___published_date, order: DESC }
    ) {
      nodes {
        id
        uid
        data {
          title {
            richText
            text
          }
          published_date
          body {
            ... on PrismicNewsArticleDataBodyImage {
              id
              slice_type
              primary {
                image {
                  alt
                  gatsbyImageData
                }
                caption {
                  richText
                }
              }
            }
            ... on PrismicNewsArticleDataBodyText {
              id
              slice_type
              primary {
                text {
                  richText
                }
              }
            }
            ... on PrismicNewsArticleDataBodyVideo {
              id
              slice_type
              primary {
                video {
                  id
                  html
                }
                caption {
                  richText
                }
              }
            }
          }
        }
      }
    }
  }
`;
