import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// SEO
import { PageSeo } from "../components/seo/page-seo";

const Page = styled.div`
  padding: 250px 30px 50px 30px;

  & .content-container {
    max-width: 910px;
    margin: 0 auto;

    & p {
      & strong {
        display: block;
        text-transform: uppercase;
        letter-spacing: 0.05em;

        margin: 3em 0 1em 0;
      }
    }

    & ul {
      & li {
        list-style: disc;
        margin: 0 0 0 1em;
      }
    }
  }
`;

const PrivacyPolicy = ({ data }) => {
  return (
    <>
      <PageSeo
        seoTitle={data.prismicPrivacyPolicy.data.title.text}
        seoText={null}
        seoImage={null}
      />

      <Page>
        <div className="content-container">
          <PrismicRichText
            field={data.prismicPrivacyPolicy.data.text.richText}
          />
        </div>
      </Page>
    </>
  );
};

export default withPrismicPreview(PrivacyPolicy);

export const query = graphql`
  query {
    prismicPrivacyPolicy {
      data {
        title {
          text
          richText
        }
        text {
          richText
        }
      }
    }
  }
`;
