import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { PrismicRichText } from "@prismicio/react";

// Utils
import { encodeForm } from "../utils/encode-form";

// Icons
import { CloseIcon } from "../icons/close-icons";

const EnquiryFormContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100000;
  background: #eee;
  color: #000;

  width: 100%;
  max-width: 610px;

  padding: 30px;

  & h2 {
    margin: 0 0 1em 0;
  }

  @media (max-width: 860px) {
    padding: 20px;
  }

  @media (max-width: 600px) {
    width: 100%;
    max-width: 90vw;
  }

  & .inner-form-container {
    height: 100%;
  }

  & form {
    & .field {
      position: relative;
      z-index: 1;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: baseline;
      justify-content: space-between;

      border-bottom: 1px solid #000;

      & input {
        width: calc(100% - 100px);

        padding: 0;
        margin: 0;
        border: 0;

        color: #000;
        background: transparent;

        font-size: 22px;
        line-height: 31px;
        letter-spacing: 0.02em;

        font-family: "Cardo", serif;

        ::-webkit-input-placeholder {
          font-size: 22px;
          line-height: 31px;
          letter-spacing: 0.02em;

          color: #999999;
          font-family: "Cardo", serif;
        }
        ::-moz-placeholder {
          font-size: 22px;
          line-height: 31px;
          letter-spacing: 0.02em;

          color: #999999;
          font-family: "Cardo", serif;
        }
        :-ms-input-placeholder {
          font-size: 22px;
          line-height: 31px;
          letter-spacing: 0.02em;

          color: #999999;
          font-family: "Cardo", serif;
        }
        :-moz-placeholder {
          font-size: 22px;
          line-height: 31px;
          letter-spacing: 0.02em;

          color: #999999;
          font-family: "Cardo", serif;
        }

        @media (max-width: 860px) {
          font-size: 15px;
          line-height: 21px;

          ::-webkit-input-placeholder {
            font-size: 15px;
            line-height: 21px;
          }
          ::-moz-placeholder {
            font-size: 15px;
            line-height: 21px;
          }
          :-ms-input-placeholder {
            font-size: 15px;
            line-height: 21px;
          }
          :-moz-placeholder {
            font-size: 15px;
            line-height: 21px;
          }
        }
      }
    }
  }

  & .privacy-container {
    color: #000;

    & p {
      margin: 0;

      & a {
        color: #000;
      }

      @media (max-width: 860px) {
        font-size: 10px;
        line-height: 14px;
      }
    }
  }

  & .submit-button {
    color: #000;

    margin: 0;

    transition: 250ms color ease;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: #999999;
      }
    }
  }

  & .close-button {
    position: absolute;
    top: 0;
    right: 0;

    padding: 15px;
    height: fit-content;
    line-height: 13px;

    & svg {
      width: 13px;
      height: auto;

      & path {
        transition: 250ms stroke ease;
      }
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        & svg {
          & path {
            stroke: #999999;
          }
        }
      }
    }

    @media (max-width: 860px) {
      line-height: 12px;

      & svg {
        width: 12px;
      }
    }
  }

  & .thank-you-message {
    margin: 0;

    & p {
      color: #000;
    }
  }

  @media (max-width: 860px) {
    font-size: 15px;
    line-height: 21px;

    & h2 {
      font-size: 15px;
      line-height: 21px;
    }
  }
`;

export const ArtworkEnquiryForm = ({
  artist,
  artwork,
  url,
  setIsArtworkEnquiryFormOpen,
}) => {
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [formMessage, setFormMessage] = useState(null);

  const websiteUrl = url;
  const subjectLine = `Artwork Enquiry: ${artist.text} - ${artwork.text}`;

  // Form Setup
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      email: "",
      url: url,
      subject: subjectLine,
    },
  });

  const onSubmit = (data, e) => {
    e.preventDefault();

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encodeForm({
        "form-name": `artwork-enquiry`,
        subject: subjectLine,
        ...data,
      }),
    })
      .then(() => {
        setIsFormVisible(false);
        setFormMessage(
          "Thank you for your interest. We will get back to you within 1-2 working days."
        );
        reset();
      })
      .catch((error) => {
        console.log(error);
        setIsFormVisible(false);
        setFormMessage(
          "An error occurred while submitting your enquiry. Please email us directly at: info@painterspaintingpaintings.com"
        );
      });
  };

  useEffect(() => {
    if (isFormVisible === false) {
      const closeForm = setTimeout(() => {
        setIsFormVisible(false);
        const fadeInForm = setTimeout(() => {
          setIsArtworkEnquiryFormOpen(false);
          setIsFormVisible(true);
        }, 750);
        return () => clearTimeout(fadeInForm);
      }, 3000);
      return () => clearTimeout(closeForm);
    }
  }, [isFormVisible]);

  return (
    <EnquiryFormContainer>
      <div className="inner-form-container">
        {isFormVisible && (
          <>
            <form
              name="artwork-enquiry"
              method="POST"
              data-netlify="true"
              netlify-honeypot="bot-field"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="top-fields">
                <h2>
                  <PrismicRichText
                    field={artist.richText}
                    components={{
                      heading2: ({ children }) => (
                        <span className="">{children}, </span>
                      ),
                    }}
                  />
                  <PrismicRichText
                    field={artwork.richText}
                    components={{
                      paragraph: ({ children }) => (
                        <span className="">{children}</span>
                      ),
                    }}
                  />

                  <button
                    className="close-button"
                    onClick={() => setIsArtworkEnquiryFormOpen(false)}
                  >
                    <CloseIcon />
                  </button>
                </h2>

                <p>For more information, email below.</p>

                <input type="hidden" name="form-name" value="artwork-enquiry" />

                <input
                  type="hidden"
                  name="url"
                  value={websiteUrl}
                  ref={register()}
                />

                <input type="hidden" name="bot-field" />
                <input
                  type="hidden"
                  name="subject"
                  value={subjectLine}
                  ref={register()}
                />

                <div className="field">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    aria-required="true"
                    aria-label="Email"
                    placeholder={`email address`}
                    {...register("email", { required: true })}
                  />

                  <button type="submit" className="submit-button">
                    send
                  </button>
                </div>
              </div>
            </form>
          </>
        )}

        {!isFormVisible && (
          <div className="thank-you-message" id="thank_you_message">
            {formMessage}
          </div>
        )}
      </div>
    </EnquiryFormContainer>
  );
};
