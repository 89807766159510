import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";

export const DefaultSEO = () => (
  <Helmet
    htmlAttributes={{ lang: "en" }}
    meta={[
      {
        name: "viewport",
        content:
          "width=device-width, user-scalable=no, initial-scale=1, maximum-scale=1",
      },
    ]}
    link={[
      {
        rel: "preload",
        href: "/fonts/fonts.css",
        as: "style",
      },
      {
        rel: "dns-prefetch",
        href: "https://images.prismic.io/repository-name",
      },
      {
        rel: "preconnect",
        href: "https://images.prismic.io/repository-name",
      },
    ]}
  />
);
