import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

export const FormatDate = (startDate, endDate) => {
  const [isDateChecked, setIsDateChecked] = useState(false);
  const [formattedDate, setFormattedDate] = useState(``);

  useEffect(() => {
    let startDateCheck;
    let endDateCheck;

    if (startDate !== null) {
      startDateCheck = dayjs(startDate);
      setIsDateChecked(true);
    }

    if (endDate !== null) {
      endDateCheck = dayjs(endDate);
      setIsDateChecked(true);
    }

    if (startDate !== null && endDate !== null) {
      const isSameYear = startDateCheck.isSame(endDateCheck, "year");
      const isSameDay = startDateCheck.isSame(endDateCheck);

      if (isSameDay) {
        setFormattedDate(`${endDateCheck.format("MMMM DD, YYYY")}`);
      } else {
        if (isSameYear) {
          setFormattedDate(
            `${startDateCheck.format("MMMM DD")} – ${endDateCheck.format(
              "MMMM DD, YYYY"
            )}`
          );
        } else {
          setFormattedDate(
            `${startDateCheck.format("MMMM DD, YYYY")} – ${endDateCheck.format(
              "MMMM DD, YYYY"
            )}`
          );
        }
      }
    } else if (startDate !== null) {
      setFormattedDate(`${startDateCheck.format("DD MMMM, YYYY")}`);
    }
  }, []);

  if (isDateChecked) return formattedDate;
};
