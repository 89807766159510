import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
html,
html.wf-loading {
  body {
    opacity: 0;
  }
}

html.wf-active,
html.wf-inactive {
  body {
    opacity: 1;
  }
}

*:focus{
  outline: none;
}

body {
  font-family: 'Cardo', serif;
  font-weight: normal;
  font-style: normal;

  font-size: 22px;
  line-height: 31px;
  font-feature-settings: 'onum' on;

  text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -webkit-tap-highlight-color: transparent;

  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;

  margin: 0;

  background-color: #eeeeee;

  transition: 300ms background-color linear, 300ms color linear, 300ms opacity linear;

  & header {
    transition: 300ms background-color linear, 300ms transform linear;
  }

  &.info,
  &.archive,
  &.news,
  &.library,
  &.privacy-policy {
    background-color: #eeeeee;

    & header {
      background-color: #eeeeee;
    }
  }

  &[class*="exhibition"] {
    background-color: #eeeeee;

    & header {
      background-color: transparent;

      &.active-background{
        background-color: #eeeeee;
      }

      @media(max-width: 860px){
        & nav{
          &.menu-open{
            background-color: #eeeeee;
          }
        }
      }
    }
  }

  &.residency {
    background-color: #000;
    color: #eeeeee;

    & header {
      background-color: #000;

    }
  }

    &.residency {
    background-color: #000;
    color: #eeeeee;

    & header {
      background-color: transparent;
      color: #eeeeee;

      &.active-background{
        background-color: #000;
        color: #eeeeee;
      }

      @media(max-width: 860px){
        & nav{
          &.menu-closed{
            color: #eeeeee;
          }

          &.menu-open{
            background-color: #000;
            color: #eeeeee;
          }
        }
      }
    }
  }

  &[class*="residency-"] {
    background-color: #000;
    color: #eeeeee;

    & header {
      background-color: transparent;
      color: #000;

      &.active-background{
        background-color: #000;
        color: #eeeeee;
      }

      @media(max-width: 860px){
        & nav{
          &.menu-closed{
            color: #eeeeee;
          }

          &.menu-open{
            background-color: #000;
            color: #eeeeee;
          }
        }
      }
    }
  }


  @media(max-width: 860px){
    font-size: 18px;
    line-height: 25px;
  }
}

input {
  border-radius: 0;
}

h1,h2,h3,h4,h5{
  font-family: 'Cardo', serif;
  font-weight: normal;

  margin: 0;
}


h1, h2, h3, h4, h5 {
  font-size: 22px;
  line-height: 31px;

  @media(max-width: 860px){
    font-size: 18px;
    line-height: 25px;
  }
}

.small-text,
.small-text > p {
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.02em;

  @media(max-width: 860px){
    font-size: 15px;
    line-height: 21px;
  }
}

.uppercase{
  text-transform: uppercase;
}

strong {
  font-weight: normal;
  font-style: normal;
}

.italic{
  font-style: italic;
}

/*--------------------------------------------------------------
  ## Media
  --------------------------------------------------------------*/

video,
img {
  width: 100%;
  margin: 0;

  display: block;
}

/*--------------------------------------------------------------
  ## Buttons
  --------------------------------------------------------------*/

  button{
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    appearance: none;

    font-weight: normal;
    font-style: normal;

    cursor: pointer;
    color: inherit;

    &:focus,
    &:hover{
      outline: none;
    }
  }

/*--------------------------------------------------------------
  ## Links
  --------------------------------------------------------------*/
a {
  color: inherit;
  text-decoration: none;

  position: relative;
}

a:hover,
a:focus,
a:active {
  color: inherit;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}

/*--------------------------------------------------------------
  ## Text
  --------------------------------------------------------------*/

p {
  margin: 1em 0;
}

ul, ol, li {
  list-style: none;
  padding: 0;
  margin: 0;
}

sup {
  font-size: 13px;
  padding: 0 0 0 2px;
}

/*--------------------------------------------------------------
  ## Colors
  --------------------------------------------------------------*/

p > a,
.mid-grey {
  color: #999999;
}

/*--------------------------------------------------------------
  ## Box Sizing / Layout
  --------------------------------------------------------------*/

div {
  box-sizing: border-box;
}

/*--------------------------------------------------------------
  ## Input Placeholders
  --------------------------------------------------------------*/

// ::-webkit-input-placeholder{
//     color: #000 !important;
//     font-family: 'MonumentGrotesk-Medium', 'Helvetica Neue', 'Lucida Grande',
//       sans-serif !important;
//     font-size: 24px !important;

//     @media (max-width: 500px) {
//       font-size: 19px !important;
//     }

// }
// ::-moz-placeholder{
//     color: #000 !important;
//     font-family: 'MonumentGrotesk-Medium', 'Helvetica Neue', 'Lucida Grande',
//       sans-serif !important;
//     font-size: 24px !important;

//     @media (max-width: 500px) {
//       font-size: 19px !important;
//     }

// }
// :-ms-input-placeholder{
//       color: #000 !important;
//     font-family: 'MonumentGrotesk-Medium', 'Helvetica Neue', 'Lucida Grande',
//       sans-serif !important;
//     font-size: 24px !important;

//     @media (max-width: 500px) {
//       font-size: 19px !important;
//     }

// }
// :-moz-placeholder {
//     color: #000 !important;
//     font-family: 'MonumentGrotesk-Medium', 'Helvetica Neue', 'Lucida Grande',
//       sans-serif !important;
//     font-size: 24px !important;

//     @media (max-width: 500px) {
//       font-size: 19px !important;
//     }

//   }

/*--------------------------------------------------------------
  ## Layout
  --------------------------------------------------------------*/

.display-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.space-between {
  justify-content: space-between;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 30px;

  @media (max-width: 860px) {
    display: block;
    grid-template-columns: unset;
    grid-column-gap: unset;
  }
}

.column-3-10 {
  grid-column: 3 / 10;

  @media (max-width: 860px) {
    grid-column: unset;
  }
}

/*--------------------------------------------------------------
  ## React Modal
--------------------------------------------------------------*/
.ReactModalPortal{
  position: fixed;

  top:0;
  left:0;
  right:0;
  bottom:0;

  z-index: 100;

  &:focus,
  &:focus-within,
  &:focus-visible {
    outline: 0;
  }
}

.ReactModalPortal-lightbox,
.ReactModalPortal-splash,
.ReactModalPortal-artworks-lightbox {
  position: fixed !important;

  top:0;
  left:0;
  right:0;
  bottom:0;

  z-index: 10000;

  &:focus,
  &:focus-within,
  &:focus-visible {
    outline: 0;
  }
}

.ReactModal__Overlay.ReactModal__Overlay--after-open{
  background-color: #fff !important;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 250ms ease;

  &:focus,
  &:focus-within,
  &:focus-visible {
    outline: 0;
  }
}

.ReactModalPortal-splash .ReactModal__Overlay,
.ReactModalPortal-lightbox .ReactModal__Overlay {
  background-color: transparent !important;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.ReactModal__Content{
  width: 100%;
  height: 100%;

  inset: unset !important;
  background: none !important;
  border-radius: 0 !important;
  padding: 0 !important;
  border: 0 !important;
}

/* @media (max-width: 600px) {
  .ReactModalPortal-lightbox .ReactModal__Overlay {
    background-color: rgba(255, 255, 255, 0.95) !important;
  }
} */

`;

export default GlobalStyle;
