import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import addToMailchimp from "gatsby-plugin-mailchimp";

const MailchimpContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  width: 100%;

  z-index: 100;
`;

const SignupFormContainer = styled.div`
  width: 100%;

  & p {
    margin: 0;
  }

  & form {
    & .field {
      position: relative;
      z-index: 1;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: baseline;
      justify-content: space-between;

      border-bottom: 1px solid #000;

      & input {
        width: calc(100% - 100px);

        padding: 0;
        margin: 0;
        border: 0;

        color: #000;
        background: transparent;

        font-size: 22px;
        line-height: 31px;
        letter-spacing: 0.02em;

        font-family: "Cardo", serif;

        ::-webkit-input-placeholder {
          font-size: 22px;
          line-height: 31px;
          letter-spacing: 0.02em;

          color: #999999;
          font-family: "Cardo", serif;
        }
        ::-moz-placeholder {
          font-size: 22px;
          line-height: 31px;
          letter-spacing: 0.02em;

          color: #999999;
          font-family: "Cardo", serif;
        }
        :-ms-input-placeholder {
          font-size: 22px;
          line-height: 31px;
          letter-spacing: 0.02em;

          color: #999999;
          font-family: "Cardo", serif;
        }
        :-moz-placeholder {
          font-size: 22px;
          line-height: 31px;
          letter-spacing: 0.02em;

          color: #999999;
          font-family: "Cardo", serif;
        }

        @media (max-width: 960px) {
          font-size: 18px;
          line-height: 25px;

          ::-webkit-input-placeholder {
            font-size: 18px;
            line-height: 25px;
          }
          ::-moz-placeholder {
            font-size: 18px;
            line-height: 25px;
          }
          :-ms-input-placeholder {
            font-size: 18px;
            line-height: 25px;
          }
          :-moz-placeholder {
            font-size: 18px;
            line-height: 25px;
          }
        }
      }
    }

    & .privacy-container {
      color: #000;

      & p {
        margin: 0;

        & a {
          color: #000;
        }

        @media (max-width: 960px) {
          font-size: 10px;
          line-height: 14px;
        }
      }

      & .errors {
        margin: 10px 0 0 0;
        grid-column: span 2;

        & p {
          font-size: 13px;
          line-height: 17px;

          color: rgba(0, 0, 0, 0.4);

          @media (max-width: 960px) {
            font-size: 18px;
            line-height: 25px;
          }
        }
      }
    }

    & .submit-button {
      color: #000;

      margin: 0;

      transition: 250ms background-color ease, 250ms color ease;
    }
  }

  & .thank-you-message {
    margin: 70px 0 0 0;

    & p {
      color: #000;
    }
  }
`;

export const InfoMailingList = () => {
  // Form Setup
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [formMessage, setFormMessage] = useState(``);
  // const [checked, setChecked] = useState(false);

  const onSubmit = (data, e) => {
    e.preventDefault();

    addToMailchimp(data.email, {})
      .then((data) => {
        // I recommend setting data to React state
        // but you can do whatever you want (including ignoring this `then()` altogether)
        if (data.result === "error") {
          if (data.msg.includes("already subscribed")) {
            setFormMessage("Thank you for signing up to our mailing list.");
            e.target.reset();
          }
        } else {
          setFormMessage("Thank you for signing up to our mailing list.");
          e.target.reset();
        }
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        // see below for how to handle errors
      });
  };

  useEffect(() => {
    if (formMessage !== ``) {
      const closeForm = setTimeout(() => {
        setFormMessage(``);
      }, 8000);
      return () => clearTimeout(closeForm);
    }
  }, [formMessage]);

  return (
    <MailchimpContainer>
      <SignupFormContainer>
        {formMessage === `` ? (
          <form
            name="signup-form"
            method="POST"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="field">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                aria-required="true"
                aria-label="Email"
                placeholder={`email address`}
                {...register("email", { required: true })}
              />

              <button type="submit" className="submit-button">
                send
              </button>
            </div>
          </form>
        ) : (
          <div className="thank-you-message">
            <p>{formMessage}</p>
          </div>
        )}
      </SignupFormContainer>
    </MailchimpContainer>
  );
};
