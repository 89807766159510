import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { useForm } from "react-hook-form";
import addToMailchimp from "gatsby-plugin-mailchimp";

// Icons
import { CloseIcon, MobileCloseIcon } from "../icons/close-icons";

const MailchimpContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: #fff;

  z-index: 100;
`;

const SignupFormContainer = styled.div`
  width: 100%;
  padding: 30px;

  & p {
    margin: 0;
  }

  & form {
    display: grid;
    grid-template-columns: auto 1fr auto auto;
    grid-column-gap: 30px;
    align-items: baseline;

    & p {
      color: #000;
    }

    & .field {
      position: relative;
      z-index: 1;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: baseline;
      justify-content: space-between;

      border-bottom: 1px solid #000;

      & input {
        width: calc(100% - 100px);

        @media (max-width: 860px) {
          width: calc(100% - 50px);
        }

        padding: 0;
        margin: 0;
        border: 0;

        color: #000;
        background: transparent;

        font-size: 22px;
        line-height: 31px;
        letter-spacing: 0.02em;

        font-family: "Cardo", serif;

        ::-webkit-input-placeholder {
          font-size: 22px;
          line-height: 31px;
          letter-spacing: 0.02em;

          color: #999999;
          font-family: "Cardo", serif;
        }
        ::-moz-placeholder {
          font-size: 22px;
          line-height: 31px;
          letter-spacing: 0.02em;

          color: #999999;
          font-family: "Cardo", serif;
        }
        :-ms-input-placeholder {
          font-size: 22px;
          line-height: 31px;
          letter-spacing: 0.02em;

          color: #999999;
          font-family: "Cardo", serif;
        }
        :-moz-placeholder {
          font-size: 22px;
          line-height: 31px;
          letter-spacing: 0.02em;

          color: #999999;
          font-family: "Cardo", serif;
        }

        @media (max-width: 1120px) {
          font-size: 18px;
          line-height: 25px;
        }

        @media (max-width: 960px) {
          font-size: 16px;
          line-height: 18px;
        }
      }
    }

    & .privacy-container {
      color: #000;

      & p {
        margin: 0;

        & a {
          color: #000;
        }
      }

      & .errors {
        margin: 10px 0 0 0;
        grid-column: span 2;

        & p {
          font-size: 13px;
          line-height: 17px;

          color: rgba(0, 0, 0, 0.4);
        }
      }
    }

    & .submit-button {
      color: #000;

      margin: 0;

      transition: 250ms background-color ease, 250ms color ease;
    }

    @media (max-width: 1120px) {
      font-size: 18px;
      line-height: 25px;

      & .field {
        & input {
          ::-webkit-input-placeholder {
            font-size: 18px;
            line-height: 25px;
          }
          ::-moz-placeholder {
            font-size: 18px;
            line-height: 25px;
          }
          :-ms-input-placeholder {
            font-size: 18px;
            line-height: 25px;
          }
          :-moz-placeholder {
            font-size: 18px;
            line-height: 25px;
          }
        }
      }
    }

    @media (max-width: 960px) {
      font-size: 16px;
      line-height: 18px;

      & .field {
        & input {
          ::-webkit-input-placeholder {
            font-size: 16px;
            line-height: 18px;
          }
          ::-moz-placeholder {
            font-size: 16px;
            line-height: 18px;
          }
          :-ms-input-placeholder {
            font-size: 16px;
            line-height: 18px;
          }
          :-moz-placeholder {
            font-size: 16px;
            line-height: 18px;
          }
        }
      }
    }
  }

  & .thank-you-message {
    & p {
      color: #000;
    }
  }

  & .mobile-close-icon {
    display: none;
  }

  @media (max-width: 860px) {
    padding: 20px;

    & form {
      display: block;
      grid-template-columns: unset;
      grid-column-gap: unset;

      & .field {
        margin: 1em 0;
      }

      & .close-banner {
        position: absolute;
        top: 20px;
        right: 20px;

        & .desktop-close-icon {
          display: none;
        }

        & .mobile-close-icon {
          display: block;
        }
      }
    }
  }
`;

export const MailingList = ({ setIsMailchimpVisible }) => {
  // Form Setup
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [formMessage, setFormMessage] = useState(``);

  const onSubmit = (data, e) => {
    e.preventDefault();

    addToMailchimp(data.email, {})
      .then((data) => {
        // I recommend setting data to React state
        // but you can do whatever you want (including ignoring this `then()` altogether)
        if (data.result === "error") {
          if (data.msg.includes("already subscribed")) {
            setFormMessage("Thank you for signing up to our mailing list.");
            e.target.reset();
          }
        } else {
          setFormMessage("Thank you for signing up to our mailing list.");
          e.target.reset();
        }
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        // see below for how to handle errors
      });
  };

  useEffect(() => {
    if (formMessage !== ``) {
      const closeForm = setTimeout(() => {
        setFormMessage(``);
        setIsMailchimpVisible(false);
      }, 8000);
      return () => clearTimeout(closeForm);
    }
  }, [formMessage]);

  return (
    <>
      <MailchimpContainer>
        <SignupFormContainer>
          {formMessage === `` ? (
            <form
              name="signup-form"
              method="POST"
              onSubmit={handleSubmit(onSubmit)}
            >
              <p>Sign up to our newsletter</p>
              <div className="field">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  aria-required="true"
                  aria-label="Email"
                  placeholder={`email address`}
                  {...register("email", { required: true })}
                />

                <button type="submit" className="submit-button">
                  send
                </button>
              </div>

              <div className="privacy-container">
                <p>
                  <span className="mid-grey">yes</span>, I accept the{" "}
                  <Link to={`/privacy-policy/`}>
                    cookie and privacy policy.
                  </Link>
                </p>
              </div>

              <button
                type="button"
                onClick={() => setIsMailchimpVisible(false)}
                className="close-banner"
              >
                <CloseIcon />
                <MobileCloseIcon />
              </button>
            </form>
          ) : (
            <div className="thank-you-message">
              <p>{formMessage}</p>
            </div>
          )}
        </SignupFormContainer>
      </MailchimpContainer>
    </>
  );
};

// <!-- Begin Mailchimp Signup Form -->
// <link href="//cdn-images.mailchimp.com/embedcode/classic-071822.css" rel="stylesheet" type="text/css">
// <style type="text/css">
// 	#mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif;  width:600px;}
// 	/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
// 	   We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
// </style>
// <div id="mc_embed_signup">
//     <form action="https://painterspaintingpaintings.us7.list-manage.com/subscribe/post?u=d62f733179f1a03e79a80ec0b&amp;id=f2a615c586&amp;f_id=00a4c7e4f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
//         <div id="mc_embed_signup_scroll">
//         <h2>Subscribe</h2>
//         <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
// <div class="mc-field-group">
// 	<label for="mce-EMAIL">Email Address  <span class="asterisk">*</span>
// </label>
// 	<input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL" required>
// 	<span id="mce-EMAIL-HELPERTEXT" class="helper_text"></span>
// </div>
// 	<div id="mce-responses" class="clear foot">
// 		<div class="response" id="mce-error-response" style="display:none"></div>
// 		<div class="response" id="mce-success-response" style="display:none"></div>
// 	</div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
//     <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_d62f733179f1a03e79a80ec0b_f2a615c586" tabindex="-1" value=""></div>
//         <div class="optionalParent">
//             <div class="clear foot">
//                 <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button">
//                 <p class="brandingLogo"><a href="http://eepurl.com/ikSmm9" title="Mailchimp - email marketing made easy and fun"><img src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg"></a></p>
//             </div>
//         </div>
//     </div>
// </form>
// </div>
// <script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script><script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='MMERGE3';ftypes[3]='text';}(jQuery));var $mcj = jQuery.noConflict(true);</script>
// <!--End mc_embed_signup--></link>
