import React from "react";

export const CloseIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="desktop-close-icon"
  >
    <path d="M1 1L17 17" stroke="black" strokeWidth="2" />
    <path d="M17 1L1 17" stroke="black" strokeWidth="2" />
  </svg>
);

export const MobileCloseIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="mobile-close-icon"
  >
    <path d="M1 1L13 13" stroke="black" />
    <path d="M13 1L1 13" stroke="black" />
  </svg>
);
